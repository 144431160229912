
export function doValidate(l) {
// par 0: Field Title
// par 1: Val
// par 2: rule

//	DatenKlassen 
// notNull
// dezimal
// datum
// int
// intMinMax
// float


	const errMsg = [];


	for (let i = 0; i < l.length; i++) {

		let title = l[i][0];
		let val = l[i][1];
		let rule = l[i][2];

		switch(rule) {
			case 'notNull':

			if (val === '' || val === null ) {
				errMsg.push(`Das Feld "${title}" darf nicht leer sein!`);
			}
			break;
			case 'dezimal':

			if (! val.match( /^\d*\,?\d*$/ ) ) {
				errMsg.push(`Das Feld "${title}" muss eine Dezimalzahl mit Komma als Trennzeichen sein`);
			}
			break;
			case 'datum':

			if (! val.match( /^(\d{1,2}\.\d{1,2}\.\d{2,4})?$/ ) ) {
				errMsg.push(`Das Feld "${title}" `);
			}
			break;

			case 'int':
			if (! val.match( /^\d+$/ ) ) {
				errMsg.push(`Das Feld "${title}" muss eine Ganzzahl sein`);
			}
			break;

			case 'intMinMax':
			let ok = false;
			let min = l[i][3];
			let max = l[i][4];

			if ( val.match( /^(\d+)$/ )) {
				ok = true;
				if (val > max) {
					ok = false;
				}
				if (val < min) {
					ok = false;
				}
			}

			if (ok === false) {
				errMsg.push(`Das Feld "${title}" muss eine Ganzzahl zwischne ${min} und ${max} sein!`);
			}
			break;

			case 'float':

			if (! val.match( /^(\d+\,?\d*)$/ ) ) {
				errMsg.push(`Das Feld "${title}" muss eine Dezimalzahl(,) oder 0 sein! `);
			}

			break;
			case 'float-or-null':

			if ( val && ! val.match( /^(\d+\,?\d*)$/ ) ) {
				errMsg.push(`Das Feld "${title}" muss eine Dezimalzahl(,) 0 oder leer sein! `);
			}
			break;
		}
	}

	return errMsg.join("<br />");
}

export function  getSidFromCookie(){


	let sid = null;

	if(document.cookie){

		let split = document.cookie.split('; ');

		for (const v  of split ){

			const match = v.match(new RegExp(/-perfectPhoneDb=(.+)/));

			if( match && match[1]){

				sid = match[1];

			} 

		}
	} // end of if
	return sid;
}


export function  isAdminProdOrPartner(){

	let resp = false;

	let sid = getSidFromCookie() || '';
	let ug = [];

	// console.log(sid);
	if (sid) {

		let base64Dec = atob(sid);

// console.log(base64Dec);
		let	r = base64Dec.match(new RegExp(/(\[.*\])/));

		// console.log(r);

		if (r != null) {
			ug = JSON.parse(r[0]);
		} 

		let par = [];

		// console.log(ug);


		if(ug.length < 1){


			return resp;
		} 

		for (var i = 0; i < ug.length; i++) {

			let userGroup = ug[i];

			if (
				userGroup.match(new RegExp(/^p-/)) ||
				userGroup.match(new RegExp(/^prod-/)) ||
				userGroup.match(new RegExp(/^admin-/)) 
				) {

				resp = true;
				return resp;

			}

		}

		return resp;


	} // end if sid


}

export function  doesUserGroupMatchTo(p){

	let sid = getSidFromCookie() || '';
	let ug = [];

	if (sid) {

		let base64Dec = atob(sid);

		let	r = base64Dec.match(new RegExp(/\[\"(.+)\"\]/));


		if (r != null) {
			ug = r[1];
		} 

	} // end if sid


	let par = [];



	if(ug.length < 1){

		return false;
	} 

	if (typeof p == 'object') {
		if(p.length > 0){
			par = p;
		}
	}

	if(par.length < 1){
		return false;
	}

	// console.log(ug);

	for (const i  of par ){

		// console.log(i);

		if (ug.match(new RegExp(i))){

			return true;
		}


	}



	return false;
}

export function  showUserGroup(){


	let ug = '';
	let sid = getSidFromCookie() || '';

	if (sid) {

		let base64Dec = atob(sid);

		let r = base64Dec.match(new RegExp(/.*\[(.*)\]/));


		if (r) {
			ug = r[1] || '';
		}

	} // end if sid

	ug = ug.replace(/\"/g, '');
	return ug;

}	



export function  formatDateOnInput(p){


	let message = '';
	let res;

	let d = '';
	let y = new Date().getFullYear() ;
	let yPrefix = y.toString().slice(0,2);
	let m = ('0' +  (new Date().getMonth() + 1)).slice(-2);
	let newDate = '';


	if ( !p || p === "0" || !p.match(/^\d/)) {

		message = 'Bitte ein valides Datum eingben!';
		newDate = '';
		d = '';
		m = '';
		y = '';

	} else {

		res = p.match(new RegExp(/(\d+)/, "g"));
		let cnt = res.length;

		// wenn das datum aus 3 zahlengruppen besteht
		if (cnt === 3) {
			d = (0 + res[0]).slice(-2);
			m = (0 + res[1]).slice(-2);

			if (res[2].length === 4) {
				y = res[2]

			} else if(res[2].length === 1){
				y = yPrefix  + 0 + (res[2]);
			} 

			else {
				y = yPrefix + (res[2].slice(-2));
			}

		} else if (cnt === 2){

			d = (0 + res[0]).slice(-2);
			m = (0 + res[1]).slice(-2);
			// y = default
		} else if (cnt === 1){

			d = (0 + res[0]).slice(-2);
			// m = default
			// y = default
		}

	}

	newDate = d + '.' + m + '.' + y;

	return [message, newDate];

}
