<template>

	<div id="messageDisplay" ref="messageDisplay"></div>

	<span class="btnMenu" @click="toggleMenu">
		<svg width="59" height="45" id="mainMenBtn"><use xlink:href="@/assets/img/icons.svg#btnMenu"/></svg>
	</span>

	<nav  ref="navMenu">
		<router-link  @click="closeMenu" to="/">Home</router-link>
		<router-link  @click="closeMenu" to="/login">Login</router-link>
		<router-link v-if="isAdminProdOrPartner"  @click="closeMenu" to="/firma">Firma</router-link>
		<router-link v-if="isAdmin"  @click="closeMenu" to="/accounts">Accounts</router-link>
	</nav>
	<router-view :serverRoot="serverRoot" />
</template>

<script>
	import * as tools from '@/tools.js'; 
	import axios from 'axios';

	export default {
		name: 'App',
		components: {
			
		},
		mounted (){
			
			// console.log(tools.showUserGroups());
			this.isAdminProdOrPartner = tools.doesUserGroupMatchTo(['p-.+', 'prod.*', 'admin']);
			this.isAdmin = tools.doesUserGroupMatchTo(['admin']);
			this.isProd = tools.doesUserGroupMatchTo(['prod.*']);
			this.isPartner = tools.doesUserGroupMatchTo(['p-.*']);

			if (this.isAdmin) {
				this.hamburgerColor = '#f88f1e';
			} else if (this.isPartner) {
				this.hamburgerColor = '#94bee3';
			}else if (this.isProd) {
				this.hamburgerColor = '#93b793';
			}

			let item = document.getElementById('mainMenBtn');
			item.style.fill = this.hamburgerColor;

		},
		data: function(){
			return {
				// serverRoot: 'http://pp.kd.workingnet.at',
				serverRoot: 'https://kds.perfect-audio.net/',
				isAdminProdOrPartner: false,
				isAdmin: false,
				isPartner: false,
				isProd: false,
				hamburgerColor: null,

			}

		},
		methods: {
			testApp(p){
				alert("testFunc " + p);
			},
			checkSid(){

				// todo


				let self = this;

				let url = this.serverRoot + '/api/checkSid.php';
				let ap = {sid: tools.getSidFromCookie()};


				const promise = axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
				});

			const dataPromise = promise.then((response) => response.data);

			return dataPromise;

			},
			setSid(p){

				let sid = tools.getSidFromCookie();

				if (sid !== p) {
					document.cookie = '-perfectPhoneDb='+ p +'; max-age=2147483647; SameSite=Lax;path=/'; 
				} else {
				// console.log("cookie no change");
			}
		},
			toggleMenu (){
				if(this.$refs.navMenu.style.display === 'flex'){
					this.$refs.navMenu.style.display = 'none';
				} else {
					this.$refs.navMenu.style.display = 'flex';

				}
			}, 			
			closeMenu (){

				this.$refs.navMenu.style.display = 'none';
			}, 

			setDisplayMessage(l){

				if (l[0]) {

					this.$refs.messageDisplay.innerHTML = 'ok';
					this.$refs.messageDisplay.style.backgroundColor = '#65a25c';

					this.$refs.messageDisplay.style.opacity = 1;
					const self = this;

					setTimeout(function(){				
						self.$refs.messageDisplay.style.opacity = 0;
					},700);

				}
				else {

					this.$refs.messageDisplay.innerHTML  = l[1];
					this.$refs.messageDisplay.style.backgroundColor = '#c82b2b';
					this.$refs.messageDisplay.style.opacity = 1;

					const self = this;
				setTimeout(function(){				
					self.$refs.messageDisplay.style.opacity = 0;
				},7000);
		}
	}
		} // end of methodes
	} // end of export default
</script>



<style lang="scss">

	html, body {
		margin: 0;
		padding: 0;
		height: 100%;

	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%;
	}

:root {
  --c11: #f88f1e;
}

	#btnNew { fill: #76b764; }
	#btnRemove { fill: #d56a6a; }
	#btnLink { fill: #618cbb; cursor: pointer;}
	#btnEMail { fill: #618cbb; cursor: pointer;}
	#btnEyeOn { fill: #fff; cursor: pointer;}

	.btnMenu {
		position: absolute;
		top: .5rem;
		right: .5rem;
		cursor: pointer;
		z-index: 100;

		& svg {
			fill: gray;

			&:hover {
				fill: #ae8755;
			}
		}
	}

	nav {
		top: 0;
		padding: 30px;
		background-color: white;
		box-shadow: 0px 0px 5px black;
		
		z-index: 1;
		position: absolute;
		width: 100vw;
		display: none;
		justify-content: center;

		a {
			font-weight: bold;
			color: #2c3e50;
			padding: 0 .2rem;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	.inputCont {
		display: flex;
	}

input, .btn, button {
		border: 1px solid silver;
		border-radius: 4px;
		display: inline-block;
		// min-width: 10rem;
		// min-height: 1rem;
		// width: 70%;
		padding: 0.1rem .6rem;
		vertical-align: middle;
}


input {


}

	.btn {
		font-size: 0.9rem;
		

	}

	#messageDisplay {
		transition: opacity 0.5s;
		background-color: #65a25c;
		position: fixed;
		padding: 2px 15px;
		top: 1em;
		left: 1em;
		border-radius: 13px;
		min-width: 2.5rem;
		// height: 39px;
		opacity: 0;
		font-weight: bold;
		color: white;
		z-index: 1000;

	}

</style>
