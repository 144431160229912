<template>

	<div class="modal" ref="selectDialogDialog">
		<div @keyup.esc = "closeModal()" class="modalCont">

			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>


				<div class="header">
					Datensatz Owner wechseln
				</div>

				<input @keyup="refreshFilter()" ref="searchbox" type="text" placeholder="Filter">

				<div v-if="indexFilter" class="index" tabindex="-1">
					<div class="headline"><span>ID</span><span>Titel</span><span>Description</span></div>
					<div class="cont" @click="selectItem(k)" v-for="(v, k) in indexFilter" v-bind:class="[v.sel ? 'sel' : '']" tabindex="0">

						<span>{{ v.id }} </span><span>{{ v.title }} </span><span>{{ v.description }}</span>					
					</div>
				</div>

				<div class="footer">
					<div @click="closeModal()" class="btn">Abbrechen</div> 
				</div>

		</div>
	</div>

</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'OwnerDialog',
		mounted(){

			this.getIndex();

			let self = this;
			setTimeout(function () {
				self.$refs.searchbox.focus();
			}, 140);

		},
		data: function(){
			return {
				index: null,
				indexFilter: null,
				dialogData : this.par,


			}
		},
		props:[
		'par'

		],
		methods:{
					selectItem(k){
			if (this.indexFilter[k].sel === true) {

				this.indexFilter[k].sel = false;
			} else {
				this.indexFilter[k].sel = true;
			}

			if (this.indexFilter[k].sel ) {
								this.dialogData.response = [this.indexFilter[k].id];
			} else {

				this.dialogData.response = [];
			}

				this.dialogData.callback();


		},

			refreshFilter(){

				const sTag = this.$refs.searchbox.value;
				let timeout = null;

				clearTimeout(timeout);

				let self = this;
				timeout = setTimeout(function () {

					self.indexFilter = [];
					for (const item  of self.index ){

						var re = new RegExp(sTag, 'ig');
						if (item.id.match( re ) ) {

							self.indexFilter.push(item);
						}
					}

				}, 400);
			},
			  		getIndex(){

				let self = this;
				const url = this.$parent.$parent.$parent.serverRoot + '/api/getOwnerIndex.php';
				let ap = {sid: tools.getSidFromCookie()};

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {},

				})
				.then(function (resp) {


					self.indexFilter = self.index = resp.data.data[1];

					// welches item ist selektiert?
					let selId = self.par.request[0];

					for (const item  of self.indexFilter  ){

						if(item.id == selId){
							item.sel = true;
						}

					}

				});

  		}, 
			
			closeModal(){

				this.dialogData.vis = false;
				this.$parent.goToRemFocus();

			}
		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				
			}

			& .footer {
				display: flex;
				justify-content: flex-end;
				
				& div.btn {
					padding: 0.1rem 0 0;
					min-width: 8.1rem;
					margin: 0 0.1rem;

					&:hover {
						background-color: var(--c11);
						color: #fff;
						border: 1px solid var(--c11);
					}	
				}
			}

		}
	}

	div.index {
		text-align: left;
		height: 35rem;
		overflow: auto;
		margin: 1rem 0rem;
		font-size: .8rem;

		& > div.headline {
			border-bottom: 1px solid silver;
			color: gray;
		}

		& > div {
			margin: 2px 0;
			padding: .1rem 0.5rem;

			& > span{
				display: inline-block;
			}

			& > span:nth-child(1){
				min-width: 6rem;
				font-weight: bold;
			}

			& > span:nth-child(2){
				min-width: 6rem;
			}

			& > span:nth-child(3){
				min-width: 6rem;
			}


		}

		&  div.cont:hover {
			background-color: #FDEECC;
			border: none;

		}

		& .sel {
			background-color: #ffff0036;
			
		}

	}

	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

</style>
