<template>


<div v-if="testBtns" class="testBtns">
	<div @click="test()">test</div>
	<div @click="test2()">test2</div>
	<div @click="test3()">test3</div>
</div>



	<div class="firmaCont" @keydown.down.exact="goToNextTabIndex" @keydown.up.exact="goToPrevTabIndex">
	
		<FirmaDialog v-if="firmaChange.vis" :par="firmaChange" />
		<SelectDialog v-if="brancheChange.vis" :par="brancheChange" />
		<SelectDialog v-if="subBrancheChange.vis" :par="subBrancheChange" />
		<ownerDialog v-if="ownerChange.vis" :par="ownerChange" />
		<SelectDialog v-if="statusChange.vis" :par="statusChange" />
		<SelectDialog v-if="kundeVonChange.vis" :par="kundeVonChange" />
		<SelectDialog v-if="betreutVonChange.vis" :par="betreutVonChange" />
		<SelectDialog v-if="landChange.vis" :par="landChange" />
		<SelectDialog v-if="geschlechtChange.vis" :par="geschlechtChange" />
		<SelectDialog v-if="titleChange.vis" :par="titleChange" />
		<SelectDialog v-if="positionChange.vis" :par="positionChange" />
		<SelectDialog v-if="anredeChange.vis" :par="anredeChange" />
		<FirmaNeu v-if="firmaNeuChange.vis" :par="firmaNeuChange" />
		<KontaktNeu v-if="kontaktNeuChange.vis" :par="kontaktNeuChange" />
		<AuftrMusikWahlDialog v-if="auftrMusikWahlChange.vis" :par="auftrMusikWahlChange" />
		<AuftrSprecherWahlDialog v-if="auftrSprecherWahlChange.vis" :par="auftrSprecherWahlChange" />
		<Dialog v-if="dialogData.vis" :par="dialogData"></Dialog>
		<AuftragNeu v-if="auftragNeuChange.vis" :par="auftragNeuChange" />

		<div class="innerCont" id="mainCont">
			<AudioPlayer id="audioPlayer" ref="audioPlayer" />
			<div class="headline"><span><img src="@/assets/img/perfectPhone_logo01.png" alt=""></span><span>DataBase</span></div>

			<div class="row">
				<div @click="firmaNeu()" class="colBtn">
					<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnNew"/></svg>
					<span>neue Firma</span>
				</div>

				<div @click="firmaLoeschen()" class="colBtn">
					<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnRemove"/></svg>
					<span>Firma löschen</span>
				</div>


			</div> <!-- end of row -->

			<div id="scrollCont">
				<div class="row">
					<div class="col c1-8">
						<span>ID</span>
						<input tabindex="0" @keyup.enter="openFirmaFromId" v-model="fDat.id" type="text"  name="id" class="">
					</div>

					<div class="col c7-8 firmaWaehlen">
						<span>
							<span @click="gotToOtherFirma('p')" title="prev"><svg class="prev" width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnPrev"/></svg></span>
							<span>Firma wählen</span>
							<span @click="gotToOtherFirma('n')" title="next"><svg class="next" width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnNext"/></svg></span>

					</span>
						<input @click.ctrl="getFirmaIndex($event)"  @keydown.alt.down.prevent="getFirmaIndex($event)" tabindex="0" v-model="fDat.firma" class="textLeft " type="text" name="firmaDisplay" title="öffnen mit CTRL Klick" readonly>
					</div>

				</div> <!-- end of row -->


				<div class="row">

					<div class="col c2-8">
						<span>betreut von:</span>
						<span class="inputCont">
							<input tabindex="0" v-model="fDat.betreutDisplay" @click.ctrl="getBetreutVonIndex($event)"  @keydown.alt.down.prevent="getBetreutVonIndex($event)" class="textLeft " type="text" name="betreutDisplay" :title="fDat.betreutDisplay" >
							<input v-model="fDat.betreut" type="hidden" name="betreut">
							<span title="betreut von entfernen!" @click="deleteParamFirma('selBox', 'betreut', 'betreutDisplay')">
								<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
							</span>
						</span>

					</div>

					<div class="col c4-8">
						<span>Status</span>
						<span class="inputCont">
							<input tabindex="0" v-model="fDat.statusDisplay" @click.ctrl="getStatusIndex($event)"  @keydown.alt.down.prevent="getStatusIndex($event)" class="textLeft " type="text" name="statusDisplay" :title="fDat.statusDisplay" title="öffnen mit CTRL Klick" readonly>
							<input v-model="fDat.status" type="hidden" name="status">
							<span title="Status entfernen!" @click="deleteParamFirma('selBox','status', 'statusDisplay')">
								<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
							</span>
						</span>

					</div>

					<!-- <div class="col c2-8 "></div> -->

					<div  class="col c2-8 cbCont" >

						<div>
							<span><input tabindex="0"  v-model="fDat.demos" @click="changeCheckboxFirma('demos', $event)" type="checkbox" name="demos" class="" /></span>
							<span>Demos</span>
						</div>

						<div>
							<span><input tabindex="0" v-model="fDat.referenz" @click="changeCheckboxFirma('referenz', $event)" type="checkbox" name="referenz" class="" /></span>
							<span>Referenz</span>
						</div>
					

						<div>
							<span><input tabindex="0" v-model="fDat.gesperrt" @click="changeCheckboxFirma('gesperrt', $event)" type="checkbox" name="gesperrt" class="" /></span>
							<span>gesperrt</span>
						</div>

					</div>

				</div> <!-- end of row -->

				<div class="row">

					<div class="col c2-8">
						<span>Branche</span>

						<span class="inputCont">
							<input tabindex="0" v-model="fDat.brancheDisplay" @click.ctrl="getBrancheIndex()"  class="textLeft " type="text" name="brancheDisplay" :title="fDat.brancheDisplay">
							<span title="Branche entfernen!" @click="brancheEntfernen()">
								<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
							</span>
						</span>
						<input v-model="fDat.branche" type="hidden" name="branche">
					</div>

					<div v-if="fDat.hasBrancheSubBranche" class="col c2-8">
						<span>SubBranche</span>
						<span class="inputCont">
						<input v-model="fDat.subbranche" type="hidden" name="subbranche">
						<input tabindex="0" v-model="fDat.subbrancheDisplay" @click.ctrl="getsubBrancheIndex()"  class="textLeft " type="text" name="subbrancheDisplay">
						<span title="Sub Branche entfernen!" @click="subBrancheEntfernen()">
							<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
						</span>
					</span>
					</div>

					<div v-else class="col c2-8 "></div>

					<div class="col c1-8 rabattCont">
						<div>
							<span>Rabatt (%)</span>
							<span><input tabindex="0" v-model="fDat.rabatt"  @blur="saveParamFirma('int', 'rabatt')" class="" type="text" name="rabatt"></span>
						</div>
					</div>

					<div class="col c3-8 kundeVonCont">
						<span>Kunde von:</span>
						<span class="inputCont">
							<span><input v-model="fDat.kunde_von" type="hidden" name="kunde_von"></span>
							<span style="width:100%"><input tabindex="0" v-model="fDat.kunde_vonDisplay" @click.ctrl="getKudneVonIndex()"  class="textLeft " type="text" name="kunde_vonDisplay" title="öffnen mit CTRL Klick" readonly></span>
							<span title="kunde von entfernen!" @click="deleteParamFirma('selBox', 'kunde_von', 'kunde_vonDisplay')" >
								<svg style="top: 8px;" width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
							</span>
						</span>
					</div>



				</div> <!-- end of row -->

				<div v-if="isAdmin" class="row">

					<div class="col c1-8">
						<span>Partner</span>
						<span class="inputCont">
							<input tabindex="0" v-model="fDat.owner" @keydown.alt.down.prevent="getOwnerIndex()" @click.ctrl="getOwnerIndex()"  class="textLeft " type="text" name="partner" title="öffnen mit CTRL Klick" readonly>
						</span>
					</div>

					<div class="col c7-8 "></div>
				</div> <!-- end of row -->


				<div class="row">

					<div class="firmaResources">
						<span @click="selFirmaRes('daten')" res="daten" v-bind:class="[isSelFRes('daten') ? 'selected' : '']">Daten</span>
						<span @click="selFirmaRes('auftraege')" res="auftraege" v-bind:class="[isSelFRes('auftraege') ? 'selected' : '']">Aufträge</span>
						<span @click="selFirmaRes('anlagen')" res="anlagen" v-bind:class="[isSelFRes('anlagen') ? 'selected' : '']">Anlagen</span>
						<span @click="selFirmaRes('anlBetreuer')" res="anlBetreuer" v-bind:class="[isSelFRes('anlBetreuer') ? 'selected' : '']">Anlagen Betreuer</span>
					</div>

				</div> <!-- end of row -->

				<div class="row">&nbsp;</div>

				<!-- Daten -->
				<div v-if="selectedFirmaRes === 'daten'">

					<div class="row">

						<div class="col c2-8">
							<span>Firma</span>
							<input tabindex="0" v-model="fDat.firma" @blur="saveParamFirma('string', 'firma')" class="textLeft " type="text" name="firmaBezeichnung" >
						</div>

						<div class="url col c2-8">
							<span>
								<span>URL 1 </span>
								<span v-if="isUrl(fDat.url1)"><a :href=fDat.url1 target="_blank" tabindex="-1"><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnLink"/></svg></a></span>
							</span>
							<input tabindex="0" v-model="fDat.url1" @blur="saveParamFirma('string', 'url1')" type="text" class="textLeft " name="url1">
						</div>



						<div class="url col c2-8" >
							<span>
								<span>URL 2 </span>
								<span v-if="isUrl(fDat.url2)"><a :href=fDat.url2 target="_blank" tabindex="-1"><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnLink"/></svg></a></span>
							</span>
							<input tabindex="0" v-model="fDat.url2" @blur="saveParamFirma('string', 'url2')" type="text" class="textLeft " name="url2">
						</div>

						<div class="col c2-8">
							<span>UID</span>
							<input tabindex="0" v-model="fDat.uid" @blur="saveParamFirma('string', 'uid')" type="text" class="textLeft " name="uid">
						</div>

					</div> <!-- end of row -->

					<div class="row">

						<div class="col c2-8">
							<span>Strasse 1</span>
							<input tabindex="0" v-model="fDat.anschrift1" @blur="saveParamFirma('string', 'anschrift1')" name="anschrift1" class="textLeft " type="text">
						</div>

						<div class="col c2-8">
							<span>Tel 1 </span>
							<input tabindex="0" v-model="fDat.tel1"  @blur="saveParamFirma('string', 'tel1')" name="tel1" class="textLeft " type="text">
						</div>

						<div class="col c2-8" >
							<span>Tel 2 </span>
							<input tabindex="0" v-model="fDat.tel2"  @blur="saveParamFirma('string', 'tel2')" name="tel2" class="textLeft " type="text">
						</div>

						<div class="col c2-8">
							<span>IBAN</span>
							<input tabindex="0" v-model="fDat.iban"  @blur="saveParamFirma('string', 'iban')" name="iban" class="textRight " type="text">
						</div>

					</div> <!-- end of row -->

					<div class="row">

						<div class="col c2-8">
							<span>Strasse 2</span>
							<input tabindex="0" v-model="fDat.anschrift2"  @blur="saveParamFirma('string', 'anschrift2')" name="anschrift2" class="textLeft " type="text">
						</div>




						<div class="email col c2-8">
							<span><span>E-Mail </span><span><a :href="'mailto:' + fDat.email1" style="color:white" tabindex="-1"><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnEMail"/></svg></a></span></span>
							<input tabindex="0" v-model="fDat.email1" @blur="saveParamFirma('string', 'email1')" name="email1" class="textLeft " type="text">
						</div>

						<div class="email col c2-8" >
							<span><span>E-Mail </span><span><a :href="'mailto:' + fDat.email2" style="color:white" tabindex="-1"><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnEMail"/></svg></a></span></span>
							<input tabindex="0" v-model="fDat.email2" @blur="saveParamFirma('string', 'email2')" name="email2" class="textLeft " type="text">
						</div>

						<div class="col c2-8">
							<span>BIC</span>
							<input tabindex="0" v-model="fDat.bic" @blur="saveParamFirma('string', 'bic')" name="bic" class="" type="text">
						</div>

					</div> <!-- end of row -->

					<div class="row">

						<div class="col c1-8">
							<span>PLZ</span>
							<input tabindex="0" v-model="fDat.anschrift4" @blur="saveParamFirma('string', 'anschrift4')" class="" name="anschrift4" type="text" >
						</div>

						<div class="col c3-8">
							<span>Ort</span>
							<input tabindex="0" v-model="fDat.anschrift5" @blur="saveParamFirma('string', 'anschrift5')" name="anschrift5" class="textLeft " type="text" >
						</div>
						<div class="col c4-8 "></div>

					</div> <!-- end of row -->

					<div class="row">

						<div class="col c4-8">
							<span>Land</span>
							<span class="inputCont">
								<input tabindex="0" v-model="fDat.landDisplay"  @click.ctrl="getLandIndex()"  name="landDisplay" class="textLeft " type="text" >
								<input v-model="fDat.land" name="land"  type="hidden" >
								<span title="Land entfernen!" @click="deleteParamFirma('selBox', 'land', 'landDisplay')">
									<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
								</span>
							</span>
						</div>
						<div class="col c4-8 "></div>

					</div> <!-- end of row -->


					<div class="row">

						<div class="col c4-8">
							<span>Aktivitäten</span>
							<textarea tabindex="0" v-model="fDat.aktivitaeten" @blur="saveParamFirma('string', 'aktivitaeten')" class="" name="aktivitaeten" rows="5"></textarea>
						</div>

						<div class="col c4-8">
							<span>Anmerkungen</span>
							<textarea tabindex="0" v-model="fDat.anmerkung" @blur="saveParamFirma('string', 'anmerkung')" class="" name="anmerkung" rows="5"></textarea>
						</div>

					</div> <!-- end of row -->

				</div>		
				<!-- Daten end -->



				<!-- Auftraege -->

				<div v-else-if="selectedFirmaRes === 'auftraege'">

					<!-- {{ auftraege = null }} -->

					{{  }}
					<div class="autragHeadCont">

						<div v-if="fDat.id > 0" class="row">
							<div @click="auftragNeu()" class="colBtn">
								<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnNew"/></svg>
								<span>neuer Auftrag</span>
							</div>

							<div @click="deleteAuftrag()" class="colBtn">
								<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnRemove"/></svg>
								<span>Auftrag löschen</span>
							</div>
						</div>

						<div class="row">

							<div class="col c8-8 auftrIndex">
								<div v-for="v,k in auftraege" @click="selAuftrag(k)" v-bind:class="[isSelAuftrag(k) ? 'selected' : '']">
									{{ k }}
								</div>
							</div>

						</div> <!-- end of row -->

					</div> <!-- end of autragHeadCont -->


					<div class="auftraegeContainer" v-if="auftraege !== null && Object.keys(auftraege).length > 0">

<div class="row">
						<div class="col c3-8">
							<span>Auftragsnummer</span>
							<input type="text" v-model="auftraege[selectedAuftrag].auftragsnummer" @blur="saveParamAuftrag('string', 'auftragsnummer')">
						</div>


						<!-- <div class="col c2-8 datumCont"> -->
							<div class="col c1-8">
								<span>Datum Lieferung</span>
								<input  @keydown.enter="formatDate(auftraege[selectedAuftrag], 'datum_lieferung', $event)" @blur="saveParamAuftrag('datum', 'datum_lieferung')" v-model="auftraege[selectedAuftrag].datum_lieferung" name="datum_lieferung"  type="text" >
							</div>

							<div  class="col c1-8">
								<span>Datum Zahlung</span>
								<input @keydown.enter="formatDate(auftraege[selectedAuftrag], 'datum_zahlung', $event)" @blur="saveParamAuftrag('datum', 'datum_zahlung')" v-model="auftraege[selectedAuftrag].datum_zahlung" type="text" >
							</div>



							<div class="col c3-8 "></div>


					</div> <!-- end of row -->

					<div class="row">

						<div class="col c1-8">
							<span>ID</span>
							<input type="text" readonly v-model="auftraege[selectedAuftrag].id">
						</div>

						<div class="col c1-8">
							<span>Datum Bestellung</span>
							<input @keydown.enter="formatDate(auftraege[selectedAuftrag], 'datum_bestellung', $event)" @blur="saveParamAuftrag('datum', 'datum_bestellung')" v-model="auftraege[selectedAuftrag].datum_bestellung" type="text">
						</div>



						<div class="col c6-8 preis1Cont">

							<table>
								<tr>
									<td>Preis1:</td>
									<td><input @blur="saveParamAuftrag('string', 'preis1')" v-model="auftraege[selectedAuftrag].preis1" type="text"></td>
									<td title="Nachlass (%)">Nachlass 1</td>
									<td><input @blur="saveParamAuftrag('string', 'nachlass1')" v-model="auftraege[selectedAuftrag].nachlass1" type="text"></td>								
									<td title="Zwischensumme Netto" >ZwischenSumme:</td>
									<td style="text-aling: right" class="sum"><label>{{ auftraege[selectedAuftrag].zwiSumNl1 }}</label></td>
								</tr>								
								<tr>
									<td></td>
									<td></td>
									<td title="Nachlass (%)">Nachlass 2</td>
									<td><input @blur="saveParamAuftrag('string', 'nachlass2')" v-model="auftraege[selectedAuftrag].nachlass2" type="text"></td>							
									<td title="Summe Netto" >Summe:</td>
									<td style="text-aling: right" class="sum"><label>{{ auftraege[selectedAuftrag].zwiSumNl2 }}</label></td>
								</tr>
							</table>

						</div> <!-- end of preis1Cont -->

<!-- 						<div class="col c1-8">
							<span>Preis</span>
							<input v-model="auftraege[selectedAuftrag].preis" type="text" >
						</div> -->

					</div> <!-- end of row -->

					<div class="row headerAuswahl">
						 <div class="col c4-8">
						 	<div @click.ctrl="getAuftrSprecherIndex($event)" class="col c2-8 auswSprecher">
						 		<span><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnLupe"/></svg></span>
						 		&nbsp;Auswahl Sprecher
						 	</div>
						 </div>
						 <div class="col c4-8">
						 	<div @click.ctrl="getAuftrMusIndex($event)" class="col c2-8 auswMusik">
						 		<span><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnLupe"/></svg></span>
						 		Auswahl Musik
						 	</div>
						 	<div  @click.ctrl="getAuftrMusLibImport($event)"  class="auswMusLib">
						 		<span><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnLupe"/></svg></span> 
						 		<span style="padding-left: 0.3rem;">Musik aus Library importieren</span>
						 	</div>
						</div>
					</div> <!-- end of row -->



					<div class="row">

						<div class="col c4-8">
							<textarea rows="5" @blur="saveParamAuftrag('string', 'sprecher_gewaehlt')" v-model="auftraege[selectedAuftrag].sprecher_gewaehlt" name="sprecher_gewaehlt"></textarea>
						</div>

						<div class="col c4-8">
							<textarea rows="5" @blur="saveParamAuftrag('string', 'musik_gewaehlt')" v-model="auftraege[selectedAuftrag].musik_gewaehlt" name="musik_gewaehlt"></textarea>
						</div>

					</div> <!-- end of row -->

						<div class="row">

							<div class="col c8-8">
								<span>Anmerkungen</span>
								<textarea rows="5" @blur="saveParamAuftrag('string', 'anmerkung')" v-model="auftraege[selectedAuftrag].anmerkung" name="anmerkunen"></textarea>
							</div>

						</div>  <!-- end of row -->


						<!-- Auftrags Files  -->

						<div class="auftragCont" @click.right.prevent="getContextMenu">

							<div v-if="contextMenuAuftrVisible" ref="contMen"  class="contMen">
								<div @click="closeContMen"><svg class="btnClose" @click.left="goToATFSelRoot" width="14" height="14"><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg></div>
								
								<div class="link" @click="selectVisibeAllAuftrFiles()">selektiere sichtbare Dateien</div>
								<div class="link" @click="deselectAllAuftrItems()">deselektiere alle</div>
								<hr>
								<div class="link" @click="downloadSelectedItem()" style="color: #217e9b">download selected</div>
								<hr>
								<div class="link" @click="createNewAtFolder()" style="color: #82a66e">create new Folder</div>
								<hr>
								<div @click="deleteSelAtItems" class="link" style="color: #c46464">delete selected</div>
							</div>

							<div class="row">
								<div class="col c8-8">
									<div class="filesHeader">

										<div v-if="auftraege[selectedAuftrag].files">
											<span><svg id="afBackBack" @click.left="goToATFSelRoot" width="24" height="24"><use xlink:href="@/assets/img/icons.svg#afBackBack"/></svg></span>
											<span><svg  id="afBack" @click.left="goToATFSelOneDown()" width="24" height="24"><use xlink:href="@/assets/img/icons.svg#afBack"/></svg></span>
										</div>
										<div>
											<span>Auftragsorder: </span><span v-if="auftraege[selectedAuftrag].files">{{ auftraege[selectedAuftrag].files[selAuftragsFile].relName }}</span>
										</div>

										<div @change="changeUpload" class="uploadAuftrFiles"><input v-on:change="tryToUploadFilesAt($event)" type="file" multiple></div>

									</div>

									<div class="filesCont">
										<span v-show="waitForDownload" class="downloadDisplay">
											<span  class="downloadPrecentage">{{ downloadPrecentage }}%</span>
											<span><svg class="waitForDownload" ref="waitForDownload" width="90" height="90" ><use xlink:href="@/assets/img/icons.svg#spinner"/></svg> </span>
										</span>

										<span v-show="uploadPrecentage" class="uploadDisplay">
											<span  class="uploadPrecentage">{{ uploadPrecentage }}%</span>
											<span><svg class="spinner" ref="spinner" width="90" height="90" ><use xlink:href="@/assets/img/icons.svg#spinner"/></svg> </span>
										</span>

										<div v-if="auftraege[selectedAuftrag].files" v-for="(d, k) in auftraege[selectedAuftrag].files[selAuftragsFile].dirs" :key="k"  @click.left.self="goInATFFolder(d)" class="d">

											
											<span><input v-model="d.sel" value="d.display"  type="checkbox" name="afCheckBox"></span><span>{{ d.display }}</span> 

										</div>	 

										<div v-if="auftraege[selectedAuftrag].files" v-for="(f, key) in auftraege[selectedAuftrag].files[selAuftragsFile].files" :key="key" class="f">

											<span><input v-model="f.sel" type="checkbox" name="afCheckBox"></span>
											<span @click="playAtAction(f, $event)" :class="bindAtFileClass(f)"></span>
											<span>{{ f.display }}</span> 
										</div>  
									</div>

									<div class="filesFooter">
										<div class="btns">
											<span @click="selectVisibeAllAuftrFiles()">select All Files</span>
											<span @click="resetAFCheckboxes()">reset All</span>
											<span @click="downloadSelectedItem()" >download selected</span>
											<span @click="deleteSelAtItems">delete selected</span>
											<span @click="createNewAtFolder()">new Folder</span>
										</div>
									</div>

								</div>
							</div>

						</div>  <!-- end of row -->

						<!-- Auftrags Files end -->
					</div> <!-- end of auftraegeContainer -->
				</div> <!-- end of Daten -->

				<!-- Auftraege end -->
				<!-- Anlagen -->
				<div v-else-if="selectedFirmaRes === 'anlagen'">
					Anlagen

				</div>
				<!-- Anlagen end -->
				<!-- AnlBetreuer -->
				<div v-else-if="selectedFirmaRes === 'anlBetreuer'">
					AnlBetreuer

				</div>
				<!-- AnlBetreuer end -->


				<!-- Kontakt Personen -->
				<div class="row" style="margin: 0.4rem 0 0.8rem; display: flex; align-items: center;">
					<div class="kontPersHl" @click="toggleKontaktCont"><svg width="24" height="24" ><use xlink:href="@/assets/img/icons.svg#btnEyeOn"/></svg><span>Kontaktpersonen</span></div>

				<div @click="kontaktNeu()" class="colBtn">
					<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnNew"/></svg>
					<span>neue Kontaktperson</span>
				</div>

				<div @click="deleteKontat()" class="colBtn">
					<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnRemove"/></svg>
					<span>Kontaktperson löschen</span>
				</div>

				</div> <!-- end of row -->

				<!-- Kontaktpersonen Container -->
				<div ref="kontaktPersCont">

					<div v-if="kontaktPersonen && kontaktPersonen.length > 0">



						<div class="row">
							<div class="kontPers">
								<div class="kontPersBtns" v-for=" v,k in kontaktPersonen">

									<!-- <div @click="selKPerson(v.id)" v-bind:class="[isSelKPerson(v.id) ? 'selected' : '']">{{ v.nameDisplay }}</div> -->


									<div @click="selKPerson(k)" v-bind:class="[isSelKPerson(k) ? 'selected' : '']">
										{{ v.nameDisplay }}
									</div>

								</div>
							</div>

						</div> <!-- end of row -->

						<div class="row">

							<div class="col c1-8">
								<span>ID</span>
								<input type="text" readonly v-model="kontaktPersonen[selectedKPerson].id" name="k-id">
							</div>

						</div> <!-- end of row -->

						<div class="row">

							<div class="col c1-8">
								<span>Geschlecht</span>
								<span class="inputCont">
									<input v-model="kontaktPersonen[selectedKPerson].geschlecht" type="hidden" >
									<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].geschlechtDisplay" @click.ctrl="getGeschlechtIndex()"  class="textLeft " name="k-geschlecht" type="text" >
									<span title="kein Geschlecht" @click="deleteParamKotaktPerson('selBox', 'geschlecht', 'geschlechtDisplay')">
									<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
								</span>
								</span>
							</div>

<!-- 							<div class="col c1-8">
								<span>&nbsp;</span>
							</div> -->

							<div class="col c1-8">
								<span>Titel</span>
								<span class="inputCont">
									<input v-model="kontaktPersonen[selectedKPerson].titel" type="hidden" >
									<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].titelDisplay" @click.ctrl="getTitleIndex()"  :title="kontaktPersonen[selectedKPerson].titelDisplay" class="textLeft " name="k-titel" type="text" >
									<span title="Title löschen" @click="deleteParamKotaktPerson('selBox', 'titel', 'titelDisplay')">
										<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
									</span>
								</span>
							</div>

							<div class="col c2-8">
								<span>Telefon</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].telefon" @blur="saveParamKontaktPerson('string', 'telefon')" class="textLeft " name="k-telefon" type="text" >
							</div>

							<div class="col c4-8 anmerkungen">
								<span>Anmerkung</span>
								<textarea tabindex="0" v-model="kontaktPersonen[selectedKPerson].anmerkung" class="" name="k-anmerkung" @blur="saveParamKontaktPerson('string', 'anmerkung')"></textarea>
							</div>

						</div> <!-- end of row -->

						<div class="row">

							<div class="col c2-8">
								<span>Vorname</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].vorname" @blur="saveParamKontaktPerson('string', 'vorname')" class="textLeft " name="k-vorname" type="text" >
							</div>

							<div class="col c2-8">
								<span>Mobil</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].mobil" @blur="saveParamKontaktPerson('string', 'mobil')" class="textLeft " name="k-mobil" type="text" >
							</div>

						</div> <!-- end of row -->

						<div class="row">

							<div class="col c2-8">
								<span>Nachname</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].nachname" @blur="saveParamKontaktPerson('string', 'nachname')" class="textLeft " name="k-nachname" type="text" >
							</div>

							<div class="col c2-8">
								<span>Benutzer</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].user" @blur="saveParamKontaktPerson('string', 'user')" class="textLeft " name="k-user" type="text" >
							</div>

						</div> <!-- end of row -->			

						<div class="row">

							<div class="col c2-8">
								<span>Postition</span>
								<span class="inputCont">
									<input v-model="kontaktPersonen[selectedKPerson].position" type="hidden" >
									<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].positionDisplay" @click.ctrl="getPositionIndex()"  class="textLeft " name="k-position" type="text" >
									<span title="Postition löschen" @click="deleteParamKotaktPerson('selBox', 'position', 'positionDisplay')">
										<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
									</span>
								</span>
							</div>

							<div class="col c2-8">
								<span>Passwort</span>
								<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].passwort" @blur="saveParamKontaktPerson('string', 'passwort')" class="textLeft " name="k-passwort" type="text" >
							</div>

						</div> <!-- end of row -->

						<div class="row">

							<div class="col c2-8">
								<span>Anrede</span>
								<span class="inputCont">
									<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].anrede" @blur="saveParamKontaktPerson('string', 'anrede')" @click.ctrl.exact="getAnredeIndex()"  class="textLeft " name="k-anrede" type="text" >
									<span title="Anrede löschen" @click="deleteParamKotaktPerson('string', 'anrede', 'anrede')">
										<svg width="12" height="12"><use xlink:href="@/assets/img/icons.svg#btnDel"/></svg>
									</span>
								</span>
							</div>

							<div class="col c2-8">

							<span><span>E-Mail </span><span><a :href="'mailto:' + kontaktPersonen[selectedKPerson].email" style="color:white" tabindex="-1"><svg width="12" height="12" ><use xlink:href="@/assets/img/icons.svg#btnEMail"/></svg></a></span></span>
							<input tabindex="0" v-model="kontaktPersonen[selectedKPerson].email" @blur="saveParamKontaktPerson('string', 'email')" class="textLeft " name="k-email" type="text">
							</div>

						</div> <!-- end of row -->

					</div> <!-- end of if kontaktPersonen -->

				</div> <!-- Kontaktpersonen Container end -->


				<!-- Kontakt Personen end  -->

			</div> <!-- end of scrollCont -->

		</div><!-- end of innerCont -->
	</div> <!-- end of frimaCont -->

</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import FirmaDialog from '@/components/FirmaDialog.vue'; 
	import SelectDialog from '@/components/SelectDialog.vue'; 
	import OwnerDialog from '@/components/OwnerDialog.vue'; 
	import FirmaNeu from '@/components/FirmaNeu.vue'; 
	import KontaktNeu from '@/components/KontaktNeu.vue'; 
	import AuftragNeu from '@/components/AuftragNeu.vue'; 
	import AuftrMusikWahlDialog from '@/components/AuftrMusikWahlDialog.vue'; 
	import AuftrSprecherWahlDialog from '@/components/AuftrSprecherWahlDialog.vue'; 
	import  Dialog from '@/components/Dialog.vue';
	import  AudioPlayer from '@/components/AudioPlayer.vue';



	export default {
		name: 'Firma',
		components: {
			FirmaDialog,
			SelectDialog,
			OwnerDialog,
			FirmaNeu,
			KontaktNeu,
			AuftragNeu,
			AuftrMusikWahlDialog,
			AuftrSprecherWahlDialog,
			Dialog,
			AudioPlayer,
		},

		mounted(){



			const self = this;
			this.$parent.$parent.checkSid().then(function (data){

				self.$parent.$parent.setSid(data.sid);
				self.$refs.kontaktPersCont.style.display = 'block';

				if (! self.$parent.$parent.isAdminProdOrPartner) {
					self.$router.replace('/');
				}

				self.isAdmin = tools.doesUserGroupMatchTo(['admin']);

				


				self.$refs.kontaktPersCont.style.display = 'none';



				// setTimeout(function(){
				// document.getElementById('scrollCont').scrollTop = 100000;
				// },600);

				// toDo DevEnv
				// self.showUserGroups();
				// self.getDataFromId(1);


			});



		}, // end mounted 
		data: function(){
			return {
				fDat: this.getFDatDefault(),
				fDatRem: {},
				selectedFirmaRes: 'daten',
				kontaktPersonen: {},
				kontaktPersonenRem: {},
				auftraege: null,
				auftraegeRem: null,
				selectedKPerson: 0,
				indexFirma: null,
				firmaChange: {vis: false, request:null, response: null, callback: null},
				brancheChange: {vis: false, request: ['Branche', '/api/getBrancheIndex.php', 'brancheSel', 'subBrancheSel'], response: null, callback: null, multiIstance: false},
				subBrancheChange: {vis: false, request: ['Sub-Branche', '/api/getSubBrancheIndex.php', 'brancheSel', 'subBrancheSel'], response: null, callback: null, multiIstance: false},
				ownerChange: {vis: false, request: ['selItem'], response: null, callback: null},
				statusChange: {vis: false, request: ['Firma Status', '/api/firma/getStatusIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				kundeVonChange: {vis: false, request: ['Kunde von:', '/api/firma/getKundeVonIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: true},
				betreutVonChange: {vis: false, request: ['Betreut von:', '/api/firma/getBetreutVonIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				landChange: {vis: false, request: ['Land:', '/api/getLandIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				geschlechtChange: {vis: false, request: ['Geschlecht:', '/api/getGeschlechtIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				titleChange: {vis: false, request: ['Titel:', '/api/getTitelIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				positionChange: {vis: false, request: ['Position:', '/api/getPositionIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				anredeChange: {vis: false, request: ['Anrede:', '/api/getAnredeIndex.php', 'selectedItem'], response: null, callback: null, multiIstance: false},
				testProp: true,
				isAdmin: null,
				firmaNeuChange: {vis: false, request:['userGroup'], response: null, callback: null},
				kontaktNeuChange: {vis: false, request:['fDat'], response: null, callback: null},
				auftragNeuChange: {vis: false, request:['fDat'], response: null, callback: null},
				focusRem: null,
				auftrMusikWahlChange: {vis: false, request:[], response: null, callback: null},
				auftrSprecherWahlChange: {vis: false, request:[], response: null, callback: null},
				selectedAuftrag: null,
				selAuftragsFile: null,
				contextMenuAuftrVisible: false,
				formData: null,
				uploadPrecentage: 0,
				waitForDownload: false,
				dialogData: {vis: false, request:null, response: null, callback: null},
				testBtns: false,



			}
		}, // end data
		  props: {
		  	serverRoot: String,

  	},
  	methods: {
  		test(){
  			this.selectedFirmaRes = 'auftraege';
  			this.getDataFromId(1);


  		},
  		test2(){
  			// this.selectedFirmaRes = 'auftraege';
  			// this.getDataFromId(1);

  			console.log(this.$refs);

  			this.uploadPrecentage = 2;

  		},
  		test3(){
  			// this.selectedFirmaRes = 'auftraege';
  			// this.getDataFromId(1);

  			console.log(this.uploadPrecentage);

  			
  			// this.uploadPrecentage = 0;

  		},
  		gotToOtherFirma(modus){
  			// modus = n,p (next, prev)

  			const self = this;
  			const url = this.$parent.$parent.serverRoot + '/api/firma/gotToOtherFirma.php';
  			let ap = {sid: tools.getSidFromCookie()};

  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: {id: this.fDat.id, modus: modus},

  			})
  			.then(function (resp) {

  				const ok = resp.data.data[0];
  				const message = resp.data.data[1];
  				let newId = null;

  				if (! ok) {

  				self.$parent.$parent.setDisplayMessage([ok, message]);

  				} else {

  				newId = resp.data.data[1];
  				self.getDataFromId(newId);

  				}

  			});


  		},
  		openFirmaFromId(e){

 			this.getDataFromId(e.target.value);
  		},

  		bindAtFileClass(o){

  			let op = '';

  			switch (o.fileType) {
  			case 'audio':
  				op = 'aBtnPlay1';
  				break;

  			case 'pdf':
  				op = 'pdfIcon';
  				break;
  				
  			case 'document':
  				op = 'wordIcon';
  				break;
  				
  			case 'sheet':
  				op = 'sheetIcon';
  				break;
  				
  			case 'archive':
  				op = 'archiveIcon';
  				break;


  			}


// 

  			return op;
  		},
  		playAtAction(o,e){


  			const root = this.$parent.$parent.serverRoot + '/media/';
  			const relFilePath = 'auftraege/' + this.fDat.id + '/' + this.selectedAuftrag + this.auftraege[this.selectedAuftrag].files[this.selAuftragsFile].relName ;
  			const link = root + relFilePath + '/' + o.display;

  			switch (o.fileType) {

  			case 'audio':
  				this.$refs.audioPlayer.playAudio(e.target, relFilePath+ '/' + o.display, 'aBtnPlay1', 'aBtnStop1');
  				break;
  			
  			case 'pdf':
  			case 'document':
  			case 'sheet':

  				window.open(link);
  				return false;
  				break;



  			}

  		},

  		atFileCheckDialog(fileNames){





  		},

  		playAudio(relFilePath, selAuftrag, fileName, e){

  			let classPlay = 'aBtnPlay1';
  			let classStop = 'aBtnStop1';

  			return;

  			const fullPathname = relFilePath + selAuftrag + '/' + fileName;

  			this.$refs.audioPlayer.playAudio(e.target, fullPathname, classPlay, classStop);

  		},

  		tryToUploadFilesAt( e){

  			// if(this.$parent.$parent.isProd){ return false; }

  			let path = '';

  			if (this.auftraege[this.selectedAuftrag].files) {
  				path = this.auftraege[this.selectedAuftrag].files[this.selAuftragsFile].fullName;
  			} else {
  				path = 'default/' + this.fDat.id + '/' + this.selectedAuftrag;
   			}


  			let fileNames = [];
  			for (const i of e.target.files) { 
  				fileNames.push(i.name);
  			} 

  			const self = this;
  			self.checkAtFileNames(path, fileNames).then(function(res){



  			self.uploadFilesAt(path, e);

  			});




  		},
  		checkAtFileNames(path, fileNames){
  			const self = this;
  			return new Promise(function(resolve, reject) {

  				const url = self.$parent.$parent.serverRoot + '/api/firma/checkAuftragFileNames.php';

  				let ap = {sid: tools.getSidFromCookie()};

  				axios.post(url, {
  					crossDomain: true,
  					headers: {
  					},
  					auth: ap,
  					data: {
  						path: path,
  						fileNames: fileNames,
  					},

  				})
  				.then(function (resp) {


  					const filesVorhanden = resp.data.data[0];

  					// console.log(self);

  						if (filesVorhanden && filesVorhanden.length > 0) {

  							self.dialogData.title = "Folgende Dateien sind schon vorhanden sollen sie überschrieben werden?";
  							self.dialogData.btn1 = "Abbrechen";
  							self.dialogData.btn2 = "OK";
  							self.dialogData.list1 = fileNames;
  							self.dialogData.request = "hello";
  							self.dialogData.vis = true;

  							const self2 = self;
  							self.dialogData.callback = function(){


  								if(self2.dialogData.response == 1){

  									resolve('done');
  								}
  							};


  						} else {
  							resolve('done');
  						}


  				});
  			});


  		},

  		changeUpload(e){


  		},
  		uploadFilesAt(path, e){

  			let fd = new FormData();

  			let cnt = 1;
  			for (const i of e.target.files) { 
  			fd.append('upload_' + cnt, i);
  			cnt++;
  			} 


  			fd.append('sid', tools.getSidFromCookie());
  			fd.append('rootPath', path);

  			this.formData = fd;

  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/firma/uploadAuftragData.php';

  			let uploadCnt = 0;


  			this.$refs.spinner.style.display = "block";
  			axios({
  				method: 'post',
					// crossDomain: true,
					// auth: ap,
  				url:  url,
  				data: fd,
  				headers: {
						// "X-CSRFToken": CSRF_TOKEN, 
  					"content-type": "multipart/form-data"
  				},
  				onUploadProgress: function(uploadEvent) {

  					self.uploadPrecentage = Math.round(uploadEvent.loaded / uploadEvent.total * 100);

  					if (self.uploadPrecentage == 100) {
  						self.uploadPrecentage = 0;
  					}
  				
  					}
  			}).then(function (resp) {

  				//todo
  				self.getAuftraegeFromFId(self.selectedAuftrag, self.selAuftragsFile);


  			});


  		},

  		downloadSelectedItem(){

  			this.waitForDownload = true;

  			const [dirs, files] = this.getSelectedAtItems();

  			if(dirs.length < 1 && files.length < 1){

  				alert("Kein Item selektiert!");

  			this.waitForDownload = false;
  				return false;
  			}


  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/firma/downloadSelAuftragItems.php';
  			let ap = {sid: tools.getSidFromCookie()};
  			let customFilename = 'PP-DB-Download_' + this.getHumanTimestamp();


  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: {dirs, files, selectedAuftrag: this.selectedAuftrag, customFilename: customFilename},
  				

  			})
  			.then(function (resp) {

  				self.contextMenuAuftrVisible = false;
  			self.waitForDownload = false;

				let filename = customFilename + '.zip';



  				const ok = resp.data.data[0];
  				const sLink = resp.data.data[1][1];

  				self.$parent.$parent.setDisplayMessage([ok, '']);

				
				const url = new URL( sLink);
  			const link = document.createElement('a');
  			link.href = url;
  			link.setAttribute('download', filename);
  			document.body.appendChild(link);
  			link.click();
  			document.body.removeChild(link);

  			});

  		},

  		createNewAtFolder(){

  			// if(this.$parent.$parent.isProd){ return false; }

  			let newFolderName = prompt("Bitte Name eingaben!");

  			if(newFolderName == ''){
  				alert("Die Eingabe ist leer!");
  				return false;
  			}

  			let path = '';
  			let filesArr = [];
  			if (this.auftraege[this.selectedAuftrag].files === null) {
  				path = 'default/' + this.fDat.id + '/' + this.selectedAuftrag;
  			} else {
  				filesArr = this.auftraege[this.selectedAuftrag].files[this.selAuftragsFile];
  			}


  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/firma/createNewAuftragFolder.php';
  			let ap = {sid: tools.getSidFromCookie()};

  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: {
  					filesArr: filesArr,
  					newFolderName: newFolderName,
  					path: path,
  				},

  			})
  			.then(function (resp) {

  				const ok = resp.data.data[0];
  				const message = resp.data.data[1];

  				self.$parent.$parent.setDisplayMessage([ok, message]);
  				self.getAuftraegeFromFId(self.selectedAuftrag, self.selAuftragsFile);

  			});

  			this.contextMenuAuftrVisible = false;
  		},
  		deselectAllAuftrItems(){

  			const state = false;

  			for (const atKey in this.auftraege) { 

  				for (const filesKey in this.auftraege[atKey].files){

  					for (const dKey in this.auftraege[atKey].files[filesKey].dirs){

  					 	this.auftraege[atKey].files[filesKey].dirs[dKey].sel = state;
  					}

  					for (const fKey in this.auftraege[atKey].files[filesKey].files){

  					 	this.auftraege[atKey].files[filesKey].files[fKey].sel = state;
  					}
  				}


  			} 
  				this.contextMenuAuftrVisible = false;



  		},
  		selectVisibeAllAuftrFiles(){





  				for (const filesKey in this.auftraege[this.selectedAuftrag].files){

  					for (const fKey in this.auftraege[this.selectedAuftrag].files[filesKey].files){

  					 	this.auftraege[this.selectedAuftrag].files[filesKey].files[fKey].sel = true;
  					}
  				}


  				this.contextMenuAuftrVisible = false;



  		},

  		getContextMenu(e){

  			if (this.contextMenuAuftrVisible) {
  				this.contextMenuAuftrVisible = false;
  			} else {
  				this.contextMenuAuftrVisible = true;

  				const self = this;
  				setTimeout(function(){
  					self.$refs.contMen.style.top = e.clientY-30+"px";
  					self.$refs.contMen.style.left = e.clientX-30+"px";

  				},1);


  			}


  		},

  		goInATFFolder(d){

  			this.selAuftragsFile =  d.key;

  		},

  		goToATFSelOneDown() {

  			this.selAuftragsFile =  this.auftraege[this.selectedAuftrag].files[this.selAuftragsFile].parentKey;

  		},  		
  		deleteSelAtItems() {

  			// if(this.$parent.$parent.isProd){ return false; }

  			const [dirs, files] = this.getSelectedAtItems();


  			if(dirs.length < 1 && files.length < 1){
  			
  				alert("Kein Item selektiert!");

  				return false;
  			}

  			if (confirm("Sollen alle selektierten Ordner(mit Inhalt) und Dateien gelöscht werden?") == false) {

  				return false;
  			} 

  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/firma/deleteSelAuftragItems.php';
  			let ap = {sid: tools.getSidFromCookie()};


  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: {dirs, files},

  			})
  			.then(function (resp) {

  				self.contextMenuAuftrVisible = false;

  				const ok = resp.data.data[0][0];
  				const message = resp.data.data[0][1];

  				self.$parent.$parent.setDisplayMessage([ok, message]);


  				self.getAuftraegeFromFId(self.selectedAuftrag, self.selAuftragsFile);


  			});


  		},
  		getSelectedAtItems(){

  			let selectedDirs = [];
  			let selectedFiles = [];



  				for (const filesKey in this.auftraege[this.selectedAuftrag].files){

  					for (const dKey in this.auftraege[this.selectedAuftrag].files[filesKey].dirs){

  						if (this.auftraege[this.selectedAuftrag].files[filesKey].dirs[dKey].sel){

  						selectedDirs.push(this.auftraege[this.selectedAuftrag].files[filesKey].dirs[dKey]);
  						}


  					}

  					for (const fKey in this.auftraege[this.selectedAuftrag].files[filesKey].files){

  						if (this.auftraege[this.selectedAuftrag].files[filesKey].files[fKey].sel){

  						selectedFiles.push(this.auftraege[this.selectedAuftrag].files[filesKey].files[fKey]);
  						}

  					}
  				} // end of root for

  				return [selectedDirs, selectedFiles];

 


  		},
  		closeContMen() {
  			this.contextMenuAuftrVisible = false;
  		},
  		goToATFSelRoot() {

  			this.selAuftragsFile = 0;
  			// this.resetAFCheckboxes();

  		},

  		selectAllAFCheckboxes(){
  			const cb = document.getElementsByName('afCheckBox');
  			for (const el of cb) {
  				el.checked = true;
  			}
  		},
  		resetAFCheckboxes(){
  			const cb = document.getElementsByName('afCheckBox');
  			for (const el of cb) {
  				el.checked = false;
  			}
  		},
  		scrollContainer(){

  			// let sc = document.getElementById('scrollCont');
  			// sc.scrollTop = -300;

  		},
  		
  		goToNextTabIndex(){


  	
  			const [tabIndex, selItem, nextItem, prevItem] = this.getCurrentTabIndex();
  			tabIndex[nextItem].focus();

  			tabIndex[nextItem].scrollIntoView({
  				behavior: 'smooth',
  				block: 'center',
  				inline: "center"
  			})

  		},  		
  		goToPrevTabIndex(){
  	
  			const [tabIndex, selItem, nextItem, prevItem] = this.getCurrentTabIndex();
  			tabIndex[prevItem].focus();

  			tabIndex[nextItem].scrollIntoView({
  				behavior: 'smooth',
  				block: 'center',
  				inline: "center"
  			})


  		},
  		getCurrentTabIndex(){

  			let tabIndex = window.document.getElementById('mainCont').querySelectorAll('[tabindex="0"]');
  			const len = tabIndex.length;
  			let selItem;

  			for (let i = 0; i < len; i++) {
  				
  				if (tabIndex[i] === document.activeElement) {
  					selItem = i;
  				} 
  			}

  			let nextItem;
  			let prevItem;


  			if (selItem >= len -1){
  				nextItem = 0;
  			} else {
  				nextItem = selItem +1;
  			}

  			if (selItem <= 0){
  				prevItem = len -1;
  			} else {
  				prevItem = selItem -1;
  			}

  			return [tabIndex, selItem, nextItem, prevItem];

  		},
  		
  		
  		refreshFDat(){
  			this.getDataFromId(this.fDat.id);
  		},
  		changeCheckboxFirma(fieldName, e){
  			// modus: checkBox


  			const statusOld = this.fDat[fieldName];

  			if(statusOld === "1" || statusOld === true){

  				this.fDat[fieldName] = false;

  			} else {

  				this.fDat[fieldName] = true;
  			}

  			this.saveParamFirma('checkBox', fieldName);
  		},
  		saveParamFirma(inputType, fieldName){

  			let oldVal = this.fDatRem[fieldName];
  			let newVal;

  			if (inputType === 'selBox') {

  				if (this.fDat[fieldName].length > 1) {

  					newVal= this.fDat[fieldName].toString();

  				} else {

  					newVal= this.fDat[fieldName][0];
  				}

  			} else {

  				newVal= this.fDat[fieldName];
  			}

  			if (newVal == oldVal) {
  				return false;
  			}

  			this.saveParameterGeneric('firma', inputType, fieldName, newVal, this.fDat.id);
  		},
  		deleteParamFirma(inputType, fieldName, displayName){

  			this.fDat[fieldName] = '';
  			this.fDat[displayName] = '';

  			this.saveParameterGeneric('firma', 'selBox', fieldName, this.fDat[fieldName], this.fDat.id);

  		},
  		saveParamKontaktPerson(inputType, fieldName){

  			let oldVal = this.kontaktPersonenRem[this.selectedKPerson][fieldName];
  			let newVal = this.kontaktPersonen[this.selectedKPerson][fieldName];

  			if (newVal == oldVal) {
  				return false;
  			}

  			this.saveParameterGeneric('kontakte', inputType, fieldName, newVal, this.kontaktPersonen[this.selectedKPerson].id);

  		},


  		deleteParamKotaktPerson(inputType, fieldName, displayName){

  			this.kontaktPersonen[this.selectedKPerson][fieldName] = '';
  			this.kontaktPersonen[this.selectedKPerson][displayName] = '';

  			this.saveParameterGeneric('kontakte', 'selBox', fieldName, this.kontaktPersonen[this.selectedKPerson][fieldName], this.kontaktPersonen[this.selectedKPerson].id);

  		},
  		saveParamAuftrag(inputType, fieldName){


  			let oldVal = this.auftraegeRem[this.selectedAuftrag][fieldName];
  			let newVal = this.auftraege[this.selectedAuftrag][fieldName];

  			if (newVal == oldVal) {
  				return false;
  			}


  			this.saveParameterGeneric('auftraege', inputType, fieldName, newVal, this.auftraege[this.selectedAuftrag].id);

  		},

  		saveParameterGeneric( table, inputType, fieldName, newVal, id ){

  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/firma/saveFirmaParam.php';
  			let ap = {sid: tools.getSidFromCookie()};

  			const params = {inputType: inputType, val: newVal, fieldName: fieldName, id: id, table: table, firmaId: this.fDat.id};

 

  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: params,

  			})
  			.then(function (resp) {

  				const ok = resp.data.data[0];
  				const message = resp.data.data[1];

    				self.$parent.$parent.setDisplayMessage([ok, message]);
  				self.refreshFDat();

  			});

  		},

  		isUrl(u){

  			if (u) {
  				return true;
  			} else {
  				return false;
  			}
  		},
  		subBrancheEntfernen(){
  			this.fDat.subbranche = '';
  			this.fDat.subbrancheDisplay = '';

  			this.saveParamFirma('selBox', 'subbranche');

  		},  		
  		brancheEntfernen(){
  			this.fDat.branche = '';
  			this.fDat.brancheDisplay = '';
  			this.fDat.hasBrancheSubBranche = false;
  			this.fDat.subbranche = '';
  			this.fDat.subbrancheDisplay = '';

  			this.saveParamFirma('selBox', 'branche');
  			this.saveParamFirma('selBox', 'subbranche');


  		},
  		showUserGroups(){
  			let ugs = tools.showUserGroup();


  		},
  		getOwnerIndex(){

  			this.setRemFocus();

  			this.ownerChange.vis = true;
  			this.ownerChange.request[0] = this.fDat.owner;

  			const self = this;
  			this.ownerChange.callback = function(){



  				self.fDat.owner= self.ownerChange.response[0];
  				

  				self.ownerChange.vis = false;



  				self.saveParamFirma('selBox', 'owner');

  				// document.getElementsByName("firmaBezeichnung")[0].focus();

  			}
  		},
  		getCheckboxStatus(p){

  			if (p == 1){

  				return true;
  			}

  			return false;
  		},
  		getGeschlechtIndex(){

  			this.setRemFocus();

  			this.geschlechtChange.vis = true;
  			this.geschlechtChange.request[2] = this.kontaktPersonen[this.selectedKPerson];
  			const self = this;

  			this.geschlechtChange.callback = function(){

  				self.kontaktPersonen[self.selectedKPerson].geschlecht = this.response[0][0];
  				self.kontaktPersonen[self.selectedKPerson].geschlechtDisplay = this.response[1];


  				self.geschlechtChange.vis = false;

  				self.saveParamKontaktPerson('selBox', 'geschlecht');
  				document.getElementsByName("k-titel")[0].focus();

  			}
  		},  		
  		getTitleIndex(){

  			this.setRemFocus();

  			this.titleChange.vis = true;
  			this.titleChange.request[2] = this.kontaktPersonen[this.selectedKPerson];
  			const self = this;

  			this.titleChange.callback = function(){

  				self.kontaktPersonen[self.selectedKPerson].titel = this.response[0][0];
  				self.kontaktPersonen[self.selectedKPerson].titelDisplay = this.response[1];

  				self.titleChange.vis = false;

  				self.saveParamKontaktPerson('selBox', 'titel');
  				document.getElementsByName("k-telefon")[0].focus();

  			}
  		},
  		getPositionIndex(){

  			this.positionChange.vis = true;
  			this.positionChange.request[2] = this.kontaktPersonen[this.selectedKPerson];
  			const self = this;

  			this.positionChange.callback = function(){

  				self.kontaktPersonen[self.selectedKPerson].position = this.response[0][0];
  				self.kontaktPersonen[self.selectedKPerson].positionDisplay = this.response[1];

  				self.positionChange.vis = false;

  				self.saveParamKontaktPerson('selBox', 'position');

  			}
  		},
  		getAnredeIndex(){

  			this.setRemFocus();

  			this.anredeChange.vis = true;
  			this.anredeChange.request[2] = this.kontaktPersonen[this.selectedKPerson];
  			const self = this;

  			this.anredeChange.callback = function(){

  				self.kontaktPersonen[self.selectedKPerson].anrede = this.response[1];


  				self.anredeChange.vis = false;

  				self.goToRemFocus();

  			}
  		},

  		getLandIndex(){

  			this.setRemFocus();

  			this.landChange.vis = true;
  			this.landChange.request[2] = this.fDat.land;
  			const self = this;

  			this.landChange.callback = function(){

  				self.fDat.land= this.response[0];
  				self.fDat.landDisplay= this.response[1];

  				self.landChange.vis = false;

  				if (self.fDatRem.land == self.fDat.land) {
  					return false;
  				}

  				self.saveParamFirma('selBox', 'land');
  				document.getElementsByName("aktivitaeten")[0].focus();
  			}
  		},


  		getBetreutVonIndex(e){

  			this.setRemFocus();

  			this.betreutVonChange.vis = true;
  			this.betreutVonChange.request[2] = this.fDat.betreut;
  			const self = this;
  			this.betreutVonChange.callback = function(){

  				self.fDat.betreut= this.response[0];
  				self.fDat.betreutDisplay= this.response[1];

  				self.betreutVonChange.vis = false;

  				self.saveParamFirma('selBox', 'betreut');

  				document.getElementsByName("statusDisplay")[0].focus();
  			}


  		},   		  		
  		getStatusIndex(e){

  			this.setRemFocus();

  			this.statusChange.vis = true;
  			this.statusChange.request[2] = this.fDat.status;
  			const self = this;
  			this.statusChange.callback = function(){

  				self.fDat.status= this.response[0];
  				self.fDat.statusDisplay= this.response[1];

  				self.statusChange.vis = false;

  				self.saveParamFirma('selBox', 'status');

  				document.getElementsByName('demos')[0].focus();

  			}

  		},   		  		
  		getKudneVonIndex(){

  			this.setRemFocus();

  			this.kundeVonChange.vis = true;
  			this.kundeVonChange.request[2] = this.fDat.kunde_von;
  			const self = this;

  			this.kundeVonChange.callback = function(){

  				self.fDat.kunde_von= this.response[0];
  				self.fDat.kunde_vonDisplay= this.response[1];

  				self.kundeVonChange.vis = false;

  				self.saveParamFirma('selBox', 'kunde_von');

  				 document.getElementsByName("kunde_vonDisplay")[0].focus();
  				

  			}

  		},    		
  		  		getBrancheIndex(){

  		  			this.setRemFocus();

  		  			this.brancheChange.vis = true;
  		  			this.brancheChange.request[2] = this.fDat.branche;
  		  			this.brancheChange.request[3] = this.fDat.subbranche;
  		  			const self = this;
  		  			this.brancheChange.callback = function(){

  		  				if (self.fDat.branche === this.response[0][0] ) {
  		  					self.brancheChange.vis = false;
  		  					return false;

  		  				} else {

  		  					self.fDat.branche= this.response[0];
  		  					self.fDat.brancheDisplay= this.response[1];

  		  					self.fDat.subbranche= '';
  		  					self.fDat.subbrancheDisplay = '';

  		  					self.fDat.hasBrancheSubBranche = this.response[2].hasBrancheSubBranche;
  		  					self.brancheChange.vis = false;

  		  					self.saveParamFirma('selBox', 'branche');
  		  					self.saveParamFirma('selBox', 'subbranche');

  		  					document.getElementsByName("brancheDisplay")[0].focus();
  		  				}

  		  			} // end of callback
  		  		},   		  		
  		  		getsubBrancheIndex(){

  		  			this.setRemFocus();

  		  			this.subBrancheChange.vis = true;
  		  			this.subBrancheChange.request[2] = this.fDat.branche;
  		  			this.subBrancheChange.request[3] = this.fDat.subbranche;

  		  			const self = this;
  		  			this.subBrancheChange.callback = function(){
  		  				
  		  				self.fDat.subbranche= this.response[0];
  		  				self.fDat.subbrancheDisplay= this.response[1];

  		  				self.subBrancheChange.vis = false;

  		  				self.saveParamFirma('selBox', 'subbranche');

  		  				document.getElementsByName("rabatt")[0].focus();
  		  			}

  		  		},


  		  		getAuftrSprecherIndex(e){

  		  			document.getElementsByName("sprecher_gewaehlt")[0].focus();
  		  			this.setRemFocus();

  		  			this.auftrSprecherWahlChange .vis = true;
  		  			const self = this;
  		  			
  		  			this.auftrSprecherWahlChange.callback = function(){

  		  				self.auftrSprecherWahlChange.vis = false;



  		  				if (self.auftrSprecherWahlChange.response[0]) {

  		  					let temp = self.auftraege[self.selectedAuftrag].sprecher_gewaehlt;

  		  					if (temp !== '') {
  		  						temp = temp + "\n";
  		  					}

  		  					temp = temp + self.auftrSprecherWahlChange.response[0];
  		  					self.auftraege[self.selectedAuftrag].sprecher_gewaehlt = temp;
  		  					
  		  					document.getElementsByName("sprecher_gewaehlt")[0].focus();
  		  				}
  		  			}
  		  		},
  		  		getAuftrMusLibImport(e){
  		  			// if(this.$parent.$parent.isProd){ return false; }

  		  			this.setRemFocus();

  		  			this.auftrMusikWahlChange.vis = true;

  		  			const self = this;

 		  			
  		  			this.auftrMusikWahlChange.callback = function(){

  		  				self.auftrMusikWahlChange.vis = false;


  		  				if (self.auftrMusikWahlChange.response[1]) {

  		  					let temp = self.auftraege[self.selectedAuftrag].musik_gewaehlt;

  		  					if (temp === null) {
  		  						temp = '';
  		  					}

  		  					if (temp !== '') {
  		  						temp = temp + "\n";
  		  					}

  		  					temp = temp + self.auftrMusikWahlChange.response[1];
  		  					self.auftraege[self.selectedAuftrag].musik_gewaehlt = temp;
  		  					self.saveParamAuftrag("string", "musik_gewaehlt");
  		  				}

  		  				if (self.auftrMusikWahlChange.response[0]) {

  		  					let musikId = self.auftrMusikWahlChange.response[0];

  		  					let path = ''; 
  		  					if (self.auftraege[self.selectedAuftrag].files) {
  		  						path = self.auftraege[self.selectedAuftrag].files[self.selAuftragsFile].fullName;
  		  					} 

  		  					const self2 = self;
  		  					const url = self.$parent.$parent.serverRoot + '/api/firma/copyMusTitleToAuftragsDir.php';
  		  					let ap = {sid: tools.getSidFromCookie()};

  		  					axios.post(url, {
  		  						crossDomain: true,
  		  						headers: {
  		  						},
  		  						auth: ap,
  		  						data: {
  		  							musId: musikId, 
  		  							auftragsId: self.selectedAuftrag, 
  		  							firmaId: self.fDat.id, 
  		  							path: path,
  		  						},

  		  					})
  		  					.then(function (resp) {

  				// todo
  		  						self.$parent.$parent.setSid(resp.data.sid);

  		  						const ok = resp.data.data[0];
  		  						const message = resp.data.data[1];

  		  						self2.$parent.$parent.setDisplayMessage([ok, message]);
  		  						self2.getAuftraegeFromFId(self2.selectedAuftrag, self2.selAuftragsFile);

  		  					});



  		  				// 	document.getElementsByName("musik_gewaehlt")[0].focus();
  		  				}
  		  			}
  		  		},
  		  		getAuftrMusIndex(e){


  		  			document.getElementsByName("musik_gewaehlt")[0].focus();
  		  			this.setRemFocus();

  		  			this.auftrMusikWahlChange .vis = true;
  		  			const self = this;
  		  			
  		  			this.auftrMusikWahlChange.callback = function(){

  		  				self.auftrMusikWahlChange.vis = false;

  		  				if (self.auftrMusikWahlChange.response[1]) {

  		  					let temp = self.auftraege[self.selectedAuftrag].musik_gewaehlt;


  		  					if (temp === null) {
  		  						temp = '';
  		  					}

  		  					if (temp !== '') {
  		  						temp = temp + "\n";
  		  					}

  		  					temp = temp + self.auftrMusikWahlChange.response[1];
  		  					self.auftraege[self.selectedAuftrag].musik_gewaehlt = temp;
  		  					self.saveParamAuftrag("string", "musik_gewaehlt");
  		  					document.getElementsByName("musik_gewaehlt")[0].focus();
  		  				}
  		  			}
  		  		},
  		  		getFirmaIndex(e){

  		  			this.setRemFocus();
  		  			this.firmaChange.vis = true;
  		  			const self = this;

  		  			this.firmaChange.callback = function(){

  		  				self.firmaChange.vis = false;

  		  				if (self.firmaChange.response[0]) {
  		  				self.getDataFromId(self.firmaChange.response[0]);
  		  				
  		  				document.getElementsByName("betreutDisplay")[0].focus();
  		  				}
  		  			}

  		  		}, 
  		  		firmaLoeschen(){

  		  			if(this.$parent.$parent.isProd){ return false; }

  		  			var check = confirm('Soll die Firma "' + this.fDat.firma + '" aus der Datenbank gelöscht werden?'); 
  		  			if (check == false) {
  		  				return false;
  		  			}


  		  			const self = this;
  		  			const url = this.$parent.$parent.serverRoot + '/api/firma/deleteFirma.php';
  		  			let ap = {sid: tools.getSidFromCookie()};

  		  			axios.post(url, {
  		  				crossDomain: true,
  		  				headers: {
  		  				},
  		  				auth: ap,
  		  				data: {fId: this.fDat.id, auftragId: this.selectedAuftrag},

  		  			})
  		  			.then(function (resp) {

  				// todo
  		  				self.$parent.$parent.setSid(resp.data.sid);

  		  				const ok = resp.data.data[0];
  		  				const message = resp.data.data[1];

  		  				self.$parent.$parent.setDisplayMessage([ok, message]);


  		  				self.fDat = self.getFDatDefault();
  		  				self.auftraege = null;
  		  			});
 

  		  		}, 	  		  		
  		  		firmaNeu(){

  		  			if(this.$parent.$parent.isProd){ return false; }


  		  			this.firmaNeuChange.vis = true;
  		  			this.firmaNeuChange.request[0] = tools.showUserGroup();

  		  			const self = this;

  		  			this.firmaNeuChange.callback = function(){

  		  				let ok = self.firmaNeuChange.response[0];
  		  				let newId = self.firmaNeuChange.response[1];
  		  				self.getDataFromId(newId);

  		  				self.firmaNeuChange.vis = false;
  		  			}
  		  		}, 	  		  		
  		  		auftragNeu(){
  		  			if(this.$parent.$parent.isProd){ return false; }

  		  			this.auftragNeuChange.vis = true;
  		  			this.auftragNeuChange.request[0] = this.fDat;

  		  			const self = this;

  		  			this.auftragNeuChange.callback = function(){

  		  				let ok = self.auftragNeuChange.response[0];
 
  		  				self.auftragNeuChange.vis = false;

  		  				self.getAuftraegeFromFId();

  		  			}
  		  		}, 	 	
  		  		getAuftraegeFromFId(remSelectedAuftrag, remSelAuftragsFile){


  		  				const self = this;
  		  				const url = self.$parent.$parent.serverRoot + '/api/firma/getAuftraegeFromFId.php';
  		  				let ap = {sid: tools.getSidFromCookie()};

  		  				axios.post(url, {
  		  					crossDomain: true,
  		  					headers: {
  		  					},
  		  					auth: ap,
  		  					data: {fId: this.fDat.id},

  		  				})
  		  				.then(function (resp) {

  		  					if(resp.data.data[1] !== null && typeof(resp.data.data[1]) === 'object'){
  		  						self.auftraege = resp.data.data[1];
  		  						self.auftraegeRem = JSON.parse(JSON.stringify(self.auftraege));



  		  						const lastKey = Object.keys(self.auftraege).pop();
  		  						self.selectedAuftrag    = lastKey;



  		  						if (remSelectedAuftrag  ) {
  		  						self.selectedAuftrag = remSelectedAuftrag;
  		  						self.selAuftragsFile = remSelAuftragsFile;

  		  		  						} else {

  		  						self.selAuftragsFile = 0;
  		  						}

  		  					} else {

  		  						self.auftraege = null;
  		  						self.auftraegeRem = JSON.parse(JSON.stringify(self.auftraege));
  		  					}

  		  				});

  		  		},  		  		
  		  		kontaktNeu(){

  		  			if(this.$parent.$parent.isProd){ return false; }
  		  			this.kontaktNeuChange.vis = true;
  		  			this.kontaktNeuChange.request[0] = this.fDat;

  		  			const self = this;

  		  			this.kontaktNeuChange.callback = function(){

  		  				let ok = self.kontaktNeuChange.response[0];
  		  				let newKontId = self.kontaktNeuChange.response[1];
 
  		  				self.kontaktNeuChange.vis = false;
  		  				self.getKontakteFromId();
  		  				self.selectKontat(newKontId);
  		  			}
  		  		}, 	
  		  		deleteAuftrag(){
  		  			if(this.$parent.$parent.isProd){ return false; }

  		  			var check = confirm('Soll der Auftrag "' + this.selectedAuftrag + '" aus der Datenbank gelöscht werden?'); 
  		  			if (check == false) {
  		  				return false;
  		  			}



  		  			const self = this;
  		  			const url = this.$parent.$parent.serverRoot + '/api/firma/deleteAuftrag.php';
  		  			let ap = {sid: tools.getSidFromCookie()};

  		  			axios.post(url, {
  		  				crossDomain: true,
  		  				headers: {
  		  				},
  		  				auth: ap,
  		  				data: {auftragId: this.selectedAuftrag, fId: this.fDat.id},

  		  			})
  		  			.then(function (resp) {

  				// // todo
  		  				self.$parent.$parent.setSid(resp.data.sid);

  		  				const ok = resp.data.data[0];
  		  				const message = resp.data.data[1];

  		  				self.$parent.$parent.setDisplayMessage([ok, message]);
  		  				self.getAuftraegeFromFId();
  		  			});

  		  		},
  		  		deleteKontat(){

  		  			if(this.$parent.$parent.isProd){ return false; }

  		  			if (!this.kontaktPersonen[this.selectedKPerson].id) {
  		  				return false;
  		  			}

  		  			const mab = this.kontaktPersonen[this.selectedKPerson].vorname + ' ' + this.kontaktPersonen[this.selectedKPerson].nachname;

  		  			var check = confirm('Soll der Mitarbeiter "' + mab + '" aus der Datenbank gelöscht werden?'); 
  		  			if (check == false) {
  		  				return false;
  		  			}

  		  			const self = this;
  		  			const url = this.$parent.$parent.serverRoot + '/api/firma/deleteKontakt.php';
  		  			let ap = {sid: tools.getSidFromCookie()};

  		  			axios.post(url, {
  		  				crossDomain: true,
  		  				headers: {
  		  				},
  		  				auth: ap,
  		  				data: {id: this.kontaktPersonen[this.selectedKPerson].id},

  		  			})
  		  			.then(function (resp) {

  				// todo
  		  				self.$parent.$parent.setSid(resp.data.sid);

  		  				const ok = resp.data.data[0];
  		  				const message = resp.data.data[1];

  		  				self.$parent.$parent.setDisplayMessage([ok, message]);
  		  				self.selectedKPerson = 0;
  		  				self.getKontakteFromId();
  		  			});

  		  		},
  		  		selectKontat(newKontId){

  		  			const self = this;

  		  			setTimeout(function(){

  		  				for ( const i in self.kontaktPersonen) {

  		  					if (self.kontaktPersonen[i].id == newKontId ){

  		  						self.selectedKPerson = parseInt(i);
  		  					}
  		  				}
  		  			},500);

  		  		},
  		  		getKontakteFromId(){

  		  				const self = this;
  		  				const url = self.$parent.$parent.serverRoot + '/api/firma/getKontakteFromId.php';
  		  				let ap = {sid: tools.getSidFromCookie()};

  		  				axios.post(url, {
  		  					crossDomain: true,
  		  					headers: {
  		  					},
  		  					auth: ap,
  		  					data: {id: self.fDat.id},

  		  				})
  		  				.then(function (resp) {

  		  					self.kontaktPersonen = resp.data.data[1];
  		  					self.kontaktPersonenRem = JSON.parse(JSON.stringify(self.kontaktPersonen));

  		  				});
  		  		},
  		  		setRemFocus(){
  		  			this.focusRem = document.activeElement;
  		  		},
  		  		goToRemFocus(){
  		  			this.focusRem.focus();
  		  		},
  		  		getDataFromId(id){

  		  			const self = this;
  		  			const url = this.$parent.$parent.serverRoot + '/api/firma/getFirmaDataFromId.php';
  		  			let ap = {sid: tools.getSidFromCookie()};

  		  			axios.post(url, {
  		  				crossDomain: true,
  		  				headers: {
  		  				},
  		  				auth: ap,
  		  				data: {id: id},

  		  			})
  		  			.then(function (resp) {


  		  				if (resp.data.data[1]) {
  		  				self.fDat = resp.data.data[1];

  		  			} else {
  		  				self.fDat =	self.getFDatDefault();
  		  			}
  		  				self.fDatRem = JSON.parse(JSON.stringify(self.fDat));

  		  				self.kontaktPersonen = resp.data.data[2];
  		  				self.kontaktPersonenRem = JSON.parse(JSON.stringify(self.kontaktPersonen));

  		  				// auftraege start //
  		  				if(resp.data.data[3] !== null && typeof(resp.data.data[3]) === 'object'){
  		  					self.auftraege = resp.data.data[3];
  		  					self.auftraegeRem = JSON.parse(JSON.stringify(self.auftraege));

  		  					const last = Object.keys(self.auftraege).pop();
  		  					self.selectedAuftrag    = last;

  		  					self.selAuftragsFile = 0;

  		  				} else {
  		  					self.auftraege = null;
  		  					self.auftraegeRem = null;
  		  					self.selectedAuftrag = null;
  		  					self.selAuftragsFile = null;

  		  				}



  		  				// auftraege end //

  		  				// console.log();
  		  				// console.log('firmaId = '+self.fDat.id);
  		  				// console.log('auftraege = '+self.auftraege);
  		  				// console.log('selectedAuftrag = '+self.selectedAuftrag);
  		  				// console.log('selAuftragsFile = '+self.selAuftragsFile);


  		  			});
  		},

  		formatDate(ob,fn,e){


  			let val = e.target.value;
  			let r = tools.formatDateOnInput(val);

				if ( r[0] !== '') {
					alert(r[0]);
					return false;
				} else {
					e.target.value = r[1];
					ob[fn] = r[1];
				}
  		}, 
  		selAuftrag(p){
  			this.selectedAuftrag = p;
  			this.selAuftragsFile = 0;
  		},
  		isSelAuftrag(p){

  			if (this.selectedAuftrag === p) {
  				return true;
  			}
  			return false;
  		},
  		toggleKontaktCont(e){

  			if (this.$refs.kontaktPersCont.style.display === '' || this.$refs.kontaktPersCont.style.display === 'block') {
  				this.$refs.kontaktPersCont.style.display = 'none';
  			} else {
  				this.$refs.kontaktPersCont.style.display = 'block';

  				const item = document.getElementById('scrollCont');
  				item.scrollTop = (item.scrollTop + item.scrollHeight); 
  			}
  		},

  		selKPerson(p){

  			this.selectedKPerson = p;
  		},
  		isSelKPerson(p){
  			if (this.selectedKPerson === p) {
  				return true;
  			}
  			return false;
  		},
  		selFirmaRes(p){

  			if (p === this.selectedFirmaRes) {
  				this.selectedFirmaRes = '';
  			} else {

  				this.selectedFirmaRes = p;
  			}
  		},  

  		isSelFRes(p){
  			if (this.selectedFirmaRes === p) {
  				return true;
  			}
  			return false;
  		},

  		mockAuftraege(){
  			return [
  			{id: 1, title: '1007'}, 
  			{id: 2, title: '2008'},
  			{id: 3, title: '2009'},
  			{id: 4, title: '2010'},
  			{id: 5, title: '2011'},
  			{id: 6, title: '2012'},
  			{id: 7, title: '2013'},
  			{id: 8, title: '2014'},
  			{id: 9, title: '2015'},

  			];
  		},

  		getFDatDefault(){

  			return {
  				"id": 0,
  				"ord": "10",
  				"user_groups": null,
  				"firma": "",
  				"sprache": "",
  				"branche": "",
  				"subbranche": "",
  				"land": "",
  				"anschrift1": "",
  				"anschrift2": "",
  				"anschrift3": null,
  				"anschrift4": "",
  				"anschrift5": "",
  				"anschrift6": "",
  				"tel1": null,
  				"tel2": null,
  				"tel3": null,
  				"fax": null,
  				"email1": null,
  				"email2": null,
  				"url1": "",
  				"url2": null,
  				"anmerkung": null,
  				"aktivitaeten": null,
  				"status": "21",
  				"rabatt": "",
  				"kampagne": "",
  				"kstatus": "0",
  				"gesperrt": null,
  				"betreut": "null",
  				"besitzer": "0",
  				"kunde_von": "",
  				"note_homepage": "0",
  				"demos": null,
  				"referenz": null,
  				"weihnachtsmailing": "",
  				"uid": "",
  				"bic": null,
  				"iban": null,
  				"anlagehersteller": "",
  				"anlage-distributor": "",
  				"anlage-distributor2": "",
  				"anlage-betreuer1": "",
  				"anlage-betreuer2": "",
  				"anlagetyp_auswahl": "",
  				"anlagetyp": null,
  				"fa_tvm_format": null,
  				"fa_tvm_laenge": "",
  				"fa_tvm_anmerkung": null,
  				"fa_moh_format": null,
  				"fa_moh_laenge": "",
  				"fa_moh_anmerkung": null,
  				"fa_ab_format": null,
  				"fa_ab_laenge": "",
  				"fa_ab_anmerkung": null,
  				"fa_anmerkung": null,

  				"betreutDisplay": "",
  			};
  		},
  		getHumanTimestamp(){

  			var date = new Date();

  			var month = date.getMonth() + 1;
  			var day = date.getDate();
  			var hour = date.getHours();
  			var min = date.getMinutes();
  			var sec = date.getSeconds();

  			month = (month < 10 ? "0" : "") + month;
  			day = (day < 10 ? "0" : "") + day;
  			hour = (hour < 10 ? "0" : "") + hour;
  			min = (min < 10 ? "0" : "") + min;
  			sec = (sec < 10 ? "0" : "") + sec;

  			var str = date.getFullYear() + "-" + month + "-" + day + "_" +  hour + "-" + min + "-" + sec;

  			return str;
  		}




		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

	input {
		text-align: right;
		outline: 1px solid transparent;

	}

	input[type=checkbox] {

	}

input[type=text]:focus,
input[type=checkbox]:focus,
textarea:focus {
  outline: 1px solid var(--c11);

}

	.firmaCont {
width: 950px;
margin: 0 auto;



		& .headline {
			height: 4rem;
			display:flex;
			justify-content: center;
			align-items: center;

			& span:nth-child(2){
				font-size: 1.3rem;
				position: relative;
				bottom: -7px;
				color: #8e8c8c;
				letter-spacing: 1.2px;
				
			}

		}

		& .innerCont {

			// border: 1px solid silver;
			// height: 100%;
			padding: 1rem;
			position: relative;
		}

	}

	.firmaCont .row {
		display:flex;
		text-align: left;
		position: relative;

		& > .col.c7-8.firmaWaehlen {
			& > span {

				& svg.next,
				& svg.prev {
					cursor: pointer;
					padding:0  .4rem;
					
				}
				
			}

		}


		& span {
			font-size: .8rem;
			margin-top: 3px;
		}


		& > div.col {
			display: flex;
			flex-direction: column;
			padding: 2px 7px;
			margin: 1px;

			background-color: #f4f4f4;
			

		}
	}

	.firmaCont .colBtn {
		border: 1px solid silver;
		border-radius: 4px;
		padding: 0.1rem 0.5rem;
		margin: 0.3rem;
		display:flex;
		align-items: center;
		cursor: pointer;


		& > * {
			cursor: pointer;
		}

		& > svg {
			margin-right: 0.3rem;
		}


		&:hover {
			background-color: var(--c11);
			border: 1px solid white;
			color: white;

			& svg {
				filter: grayscale(1) contrast(0);

			}

		}
		
	}

	#scrollCont {
		height: 52rem;
		overflow: scroll;
	}

	textarea {
		 resize:vertical;
		 padding: 0.1rem 0.4rem;
	}


	.c8-8 {
		width: 911px;
	}

	.c7-8 {
		width: 795px;
	}

	.c6-8 {
		width: 679px;
	}

	.c5-8 {
		width: 563px;
	}

	.c4-8 {
		width: 447px;
	}

	.c3-8 {
		width: 332px;
	}

	.c2-8 {
		width: 216px;
	}

	.c1-8 {
		width: 100px;
	}


	.colBlank {
	}

	.row .firmaResources {
		background-color: var(--c11);
		height: 2rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
		color: white;
		margin: 0;
		margin: 5px 0;
		width: 925px;
		border-radius: 6px;
		box-shadow: 2px 2px 3px #a8a8a8;

		& > .selected {
			background-color: white;
			color: black;

		}
		& span {
			margin: 0;
			border: 1px solid white;

			padding: 3px 16px;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				filter: contrast(0.8);
				color: var(--c11);
				background-color: white;
			}



		}
	}

	.firmaCont .row div.url span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

	.firmaCont .row div.email span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

		.firmaCont .row div.icLupe span  {
		position: relative;
		cursor: pointer;

		& span:nth-child(2) {
			position: absolute;
			bottom: -2px;
			left: 5rem;
		}
	}

	.firmaCont .row div.cbCont  {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-items: flex-end;

		& span:nth-child(2) {
			font-size: .7rem;
		}
		
	}

	.firmaCont .row div.rabattCont  {
		display: flex;
		flex-direction: row;

		& > div span {
			font-size: .7rem;
		}
		// flex-direction: row;
		// align-items: center;

		& div:nth-child(1) input {
			width: 3rem;
		}
		& div:nth-child(2) input {
			width: 6rem;
		}
		
	}

	.firmaCont .row div.kundeVonCont {
		& input {
			width: 93%
		}
		
	}

	.row .kontPersHl {

		background-color: #88847f;
		display: flex;
		color: white;
		cursor: pointer;
		box-shadow: 2px 2px 3px #a8a8a8;
		font-size: 0.8rem;
		letter-spacing: 0.9px;
		height: 1rem;
		padding: 0.2rem 0.5rem;
		border-left: 9px solid var(--c11);

		& svg {
			bottom: 2px;
			position: relative;
			left: -3px;
			
		}

	}




	.row .kontPers {

		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}


	.row .kontPers > .kontPersBtns {

		flex: 0 0 24.6%;
		// background-color: silver;
		margin: 1px;


			
		& div {
			cursor: pointer;
			padding: 0.2rem;
			font-size: 0.9rem;
			margin: 1px 0.5rem;
			min-width: 7rem;
			color: white;
			text-align: center;
			letter-spacing: 0.4px;
			background-color: #bfbfbf;

			&:hover {
				background-color: #b79a7b;
				color: white;
				
			}

			&.selected {
  color: white;
  background-color: var(--c11);


				
			}
			
		}
		
	}

	.firmaCont .row div.anmerkungen {
		position: absolute;
		right: 0;
		width: 47.7%;
		z-index: 10;
		& textarea {

		height: 196px;
		}
		
	}

	.firmaCont  .autragHeadCont div.row {

		& div.auftrIndex {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-content: flex-start;
			justify-content: flex-start;

			& div {
				padding: 0 0.6rem;
				background-color: #fff;
				margin: 3px;
				border-radius: 4px;
				border: 1px solid #d5d5d5;
				font-size: .8rem;
				line-height: 1.3rem;
				cursor: pointer;

				&:hover {
					background-color: #F9ECD3;
					
				}

				&.selected {
					border: 1px solid var(--c11);
					background-color: #eea250;
					color: white;
					font-weight: bold;
				}
				
			}
			

		}
		
		& div.datumCont {

			display: flex;
			flex-direction: row;

			& input {
				width: 5rem;
				
			}
		}
	}


	.firmaCont .row > div.col {

	justify-content: flex-end;
		
	}


	.firmaCont .row > div.col.preis1Cont {

		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: .75rem;

		& table {

			& td {
				padding: 0 .5rem;

				&.sum {
					font-weight: bold;
					background-color: #a6a6a6;
					min-width: 4rem;
					border-radius: 4px;
					color: white;

					
				}
			}

			& input {
				width: 4rem;
				text-align: right;
			}

			& label {
				width: 100%;
				display: inline-block;
				text-align: right;



			}
			
		}
	}


	.firmaCont .row.headerAuswahl {
		display: flex;
		& > div:nth-child(1) {

			& .auswSprecher {
				background-color: var(--c11);
				border-radius: 4px;
				color: white;
				font-size: .85rem;
				padding: 0.2rem 0.5rem 0rem;
				border: 1px solid white;
				fill: white;
				&:hover {
					color: var(--c11);
					border: 1px solid var(--c11);
					background-color: white;
					fill: var(--c11);
				}
			}
		}
		
		& > div:nth-child(2) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
			& .auswMusik {
				background-color: var(--c11);
				border-radius: 4px;
				color: white;
				font-size: .85rem;
				padding: 0.2rem 0.5rem 0rem;
				fill: white;

				&:hover {
					color: var(--c11);
					border: 1px solid var(--c11);
					background-color: white;
					fill: var(--c11);
				}
			}	

			& .auswMusLib {
				width: 12rem;
				border: 1px solid silver;
				border-radius: 4px;
				padding: .1rem .5rem 0;
				font-size: .85rem;
				text-align: center;

				&:hover {
					color: var(--c11);
					border: 1px solid var(--c11);
					background-color: white;
					fill: var(--c11);
				}
			}
		}
	}

	.firmaCont .row.auftragCont {

		display: flex;
		font-size: .8rem;




		& > div {
			display: flex;
			flex-direction: row;


			& > div:nth-child(1){

			}
			

			& > div:nth-child(2){

				border: 1px solid silver;
				border-radius: 4px;
				padding: .1rem .6rem 0;
				text-align: center;

				&:hover {
					color: var(--c11);
					border: 1px solid var(--c11);
					background-color: white;
					fill: var(--c11);
				}
					
			}
			
		}

	}


.textLeft {
	text-align: left;
}

.textCenter {
	text-align: center;
}

.textRight {
	text-align: right;
}

.inputCont {
	position: relative;

	

	& > input {
		width: 100%;
	}

	& svg {
		position: absolute;
		right: .2rem;
		top: 5px;
		cursor: pointer;

	}
	
}

.auftragCont {

// 
background-color: #ea861b;
padding: 1px;
		
}

.filesCont {

	position: relative;
	display: flex;
	flex-wrap: wrap;
	font-size: .85rem;

	& > div {
		flex: 0 1 25%;
		padding: .1rem .2rem;
		margin: 0.2rem .2rem;


			border: 1px solid silver;
			border-radius: 4px;

			&.d {
				background-color: #FFF799;
				color: #595858;				
			}
					&.f {
		
			}
			
		
	}
	
}

.filesHeader {

	position: relative;
	display: flex;
	min-height: 3em;
	& div:first-child {
		& span {
			vertical-align: bottom;
			display: inline-block;
			margin-right: 5px;
		}
		
	}

	& svg {
		background-color: transparent;
		border-radius: 4px;
		fill: gray;

		&:hover  {
			fill: white;
			background-color: silver;


		}
	}

	& div:nth-child(2) {
		& span {
			position: relative;
			top: .5rem;
		}
	}
	& .uploadAuftrFiles {
		position: absolute;
		right: -1px;
		top: 4px;
		z-index: 100;

		& input {
			border: none;

			&[type="file"] {

			}
		}
		 
	}
}

.filesFooter {
	background-color: #DDD;	
	margin-top: 0.5rem;
	& div.btns {

		& span {
			border: 1px solid silver;
			padding: 0.1rem 0.5rem;
			background-color: white;
			min-width: 9rem;
			display: inline-block;
			border-radius: 4px;
			margin: 4px;
			cursor: pointer;
			&:hover {
				background-color: #a2a2a2;
				color: white;
			}

			&:nth-child(3):hover {
				background-color: green;
			}
		
			&:nth-child(4):hover {
				background-color: red;
			}		
			&:nth-child(5):hover {
				background-color: #9b9b49;
			}
			
		}
	}
}

.contMen {
	background-color: #fff;
	position: absolute;
	top: 647px;
	left: 190px;
	z-index: 101;
	min-width: 12rem;
	min-height: 6rem;
	border: 1px solid silver;
	border-radius: 6px;
	font-size: 0.85rem;
	text-align: left;
	padding: 10px 8px;
	& div {
		padding: 2px 16px;
		&.link:hover {
			cursor: pointer;
			background-color: #FFFCD9;

		}
	}

	& div svg.btnClose {
		position: absolute;
		right: .2rem;
		top: .1rem;
		cursor: pointer;
		z-index: 100;

	}
}

hr {
	border: none;
	border-top: 1px solid #a4a4a4;
	width: 90%;
}

.uploadDisplay {
position: absolute;
top: -68px;
right: 157px;
z-index: 100;
background: #fff;
padding: 1rem;
border-radius: 50%;
width: 4rem;
text-align: center;
height: 4rem;
line-height: 4rem;
box-shadow: 3px 3px 6px #9fa0a2;

}

.waitForDownload {
	position: absolute;
	top: 2px;
	left: 4px;
	z-index: 100;
	fill: #5e6aac !important;
	display: block;

	animation: rotate 3.2s linear infinite, mymove 10s infinite alternate;

}

.spinner {
	position: absolute;
	top: 2px;
	left: 4px;
	z-index: 100;
	fill: #1d70c8;
	display: block;

	animation: rotate 3.2s linear infinite, mymove 10s infinite alternate;

}

@keyframes rotate {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(00deg);
	}

}

@keyframes mymove {
  from {fill: #1d70c8;}
  to {fill: #7299c1;}
}

#audioPlayer {
position: absolute;
top: 15px;
left: 15px;
z-index: 100;

}

.aBtnPlay1,
.aBtnStop1 {
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 20px;
top: -3px;
position: relative;
}

.aBtnPlay1 {

	&:before {

		content: url("@/assets/img/aBtnPlay.svg");

	}	
}

.aBtnStop1 {


	animation-name: rotStopBtn;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

		

	&:before {
		content: url("@/assets/img/aBtnStop.svg");
	}
}



@keyframes rotStopBtn {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.pdfIcon {

		cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 20px;


	&:before {

		content: url("@/assets/img/pdfIcon.svg");

	}	
}
.wordIcon {

	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 20px;

	&:before {

		content: url("@/assets/img/wordIcon.svg");

	}	
}
.sheetIcon {

	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 20px;

	&:before {

		content: url("@/assets/img/sheetIcon.svg");

	}	
}
.archiveIcon {

	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	width: 20px;

	&:before {

		content: url("@/assets/img/archiveIcon.svg");

	}	
}

.testBtns {
position: absolute;
right: 5rem;
z-index: 1000;

	& > div {
		width: 12rem;
		height: 2rem;
		margin: .2rem auto;
		border-radius: 4px;
		border: 1px solid silver;
		cursor: pointer;
		vertical-align: middle;
		line-height: 2rem;		
	}
}


</style>
