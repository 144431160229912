import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Firma from '../views/Firma.vue'
import Accounts from '../views/Accounts.vue'
import Test from '../views/Test.vue'
	import * as tools from '../tools.js'; 

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },  
  {
    path: '/login/:li',
    name: 'login',
    component: Login
  },  

  

  {
    path: '/test',
    name: 'test',
    component: Test
  }
];

if(tools.doesUserGroupMatchTo(['p-.+', 'prod.*', 'admin'])){

	routes.push( 

		{
			path: '/firma',
			name: 'firma',
			component: Firma
		},  

	);
}

if(tools.doesUserGroupMatchTo(['admin'])){
	routes.push( 

	{
		path: '/accounts',
		name: 'accounts',
		component: Accounts
	},  

	);
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
