<template>
  <div class="home">

    <div class="headline"><span><img src="@/assets/img/perfectPhone_logo01.png" alt=""></span><span>DataBase</span></div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  props: {

  	},
  	mounted(){

  	},
  components: {
  },
		data: function(){
			return {
				homeTest: "homeTest",

			}
		},
		methods: {
			test(){
				alert("");
			}
		}

	} // end of export defaut
</script>

<style lang="scss" scoped>
	
.headline {
	margin: 1rem;
}

</style>
