<template>
		<div><audio v-show="isPlaying" id="wlAPlayer" ref="wlAPlayer" controls></audio></div>
</template>

<script>


	export default {
		name: 'audioPlayer',
		components: {

		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
				root: this.$parent.$parent.$parent.serverRoot + '/media',
				isPlaying: false,
				actFile: '',
				target: '',
				remClassStop: '',
				remClassPlay: '',

			}
		}, // end data
		methods: {

				playAudio(target, filename, classPlay, classStop){

					let fullFile = this.root + '/'  + filename;

					if (this.isPlaying) { 
						if (filename ===  this.actFile) {

							// stop player
							document.getElementById('wlAPlayer').pause();

							target.classList.remove(classStop);
							target.classList.add(classPlay);

							this.isPlaying = false;
							this.actFile = '';
							this.target = '';
							this.remClassStop = '';
							this.remClassPlay = '';

						} else {
							// play new File

							this.target.classList.remove(classStop);
							this.target.classList.add(classPlay);	

							document.getElementById('wlAPlayer').src = fullFile;
							document.getElementById('wlAPlayer').load();
							document.getElementById('wlAPlayer').play();

							target.classList.remove(classPlay);
							target.classList.add(classStop);

							this.isPlaying = true;
							this.actFile = filename;
							this.target = target;
							this.remClassStop = classStop;
							this.remClassPlay = classPlay;

						}

					} else {
						

						//play

						document.getElementById('wlAPlayer').src = fullFile;
						document.getElementById('wlAPlayer').load();
						document.getElementById('wlAPlayer').play();

						target.classList.remove(classPlay);
						target.classList.add(classStop);

						this.isPlaying = true;
						this.actFile = filename;
						this.target = target;
						this.remClassStop = classStop;
						this.remClassPlay = classPlay;

					}

					const self = this;
					document.getElementById("wlAPlayer").onended = function() {


						self.target.classList.remove(classStop);
						self.target.classList.add(classPlay);

						self.isPlaying = false;
						self.actFile = '';
						self.target = '';
						self.remClassStop = '';
						self.remClassPlay = classPlay;
					}



				},
				stopPlaying(){

					document.getElementById('wlAPlayer').pause();

					if(typeof this.target == 'object'){
					this.target.classList.remove(this.remClassStop);
					this.target.classList.add(this.remClassPlay);
				}

					this.isPlaying = false;
					this.actFile = '';
					this.target = '';
					this.remClassStop = '';
					this.remClassPlay = '';

				},

				
		} // end methods
	} // end export default

</script>



<style lang="scss">

#wlAPlayer {

}



</style>
