<template>

	<AccountEdit v-if="accountEditChange.vis" :par="accountEditChange" />

	<div class="accountsCont">

		<div class="btns">
			<div @click="userNew()" class="colBtn">
				<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnNew"/></svg>
				<span>neuer User</span>
			</div>

		</div>

		

		<div class="userList">
			<div class="header">User:</div>
			<div v-if="users" class="cont">
				

				<div class="row hl">
					<div class="item"></div>
					<div class="item">id</div>
					<div class="item">user</div>
					<div class="item">group_id</div>
					<div class="item">groups_comments</div>
				</div>

				<div class="scrollCont">
					<div v-for="u in users" class="row">
						<div @click="editUser(u)" class="item">
							<svg width="14" height="14" ><use xlink:href="@/assets/img/icons.svg#iconEdit"/></svg>
						</div>
						<div class="item">{{u.id}}</div>
						<div class="item">{{u.user}}</div>
						<div class="item">{{u.group_id}}</div>
						<div class="item">{{u.groups_comments}}</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import AccountEdit from '@/components/AccountEdit.vue'; 

	export default {
		name: 'Accounts',
		components: {
			AccountEdit
		},
		beforeMount(){

		},
		mounted(){

			// this.$parent.$parent.checkSid().then(function(data){

				// this.$parent.$parent.setSid(data.sid);

				this.getUsersIndex();

			// });




		}, // end mounted 
		data: 
		function(){
			return {
				users: null,
				accountEditChange: {vis: false, request:['userObj','modus:edit|new'], response: null, callback: null},

			}
		}, // end data
		  props: {
		  	serverRoot: String,

  	},
  	methods: {
  		test(){

  		},
  		isInUserGroup(){
  			// return tools.isInUserGroup([1,2]);
  		},
  		userNew(){
  			this.accountEditChange.vis = true;

  			this.accountEditChange.request[0] = {
  				"id":"0",
  				"group_id":"",
  				"groups_comments":"",
  				"user":"",
  				"password":null
	}
  			this.accountEditChange.request[1] = 'new';

  			const self = this;

  			this.accountEditChange.callback = function(){


  			let ok = self.accountEditChange.response[0];
  			let newId = self.accountEditChange.response[1];

  			self.$parent.$parent.setDisplayMessage([ok, newId]);

  			self.getUsersIndex();

  				self.accountEditChange.vis = false;
  			}
  		},


  	editUser(o){
  		this.accountEditChange.vis = true;
  		this.accountEditChange.request[0] = o;
  		this.accountEditChange.request[1] = 'edit';

  		const self = this;

  		this.accountEditChange.callback = function(){

  			let ok = self.accountEditChange.response[0];
  			let newId = self.accountEditChange.response[1];
  			self.$parent.$parent.setDisplayMessage([ok, '']);


  		self.getUsersIndex();

  			self.accountEditChange.vis = false;
  		}
  	}, 
  	getUsersIndex(){
  		
  			const self = this;
  			const url = self.$parent.$parent.serverRoot + '/api/getUsersIndex.php';
  			let ap = {sid: tools.getSidFromCookie()};



  			axios.post(url, {
  				crossDomain: true,
  				headers: {
  				},
  				auth: ap,
  				data: {},
  				

  			})
  			.then(function (resp) {

  				self.users = resp.data.data[1];



  			});

  	},


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

	.accountsCont {
		max-width: 40rem;
		margin: 3rem auto;

		padding: 1rem;
		border: 1px solid silver;
		border-radius: 6px;

		
	}

	.userList {

		& div.header {
			background-color: var(--c11);
			color: white;
			padding: 0.1rem;
			border-radius: 4px;
			margin-bottom: 1rem;
			
		}

		& div.cont {
			display: flex;
			flex-wrap: wrap;

			div.scrollCont {
				height: 7rem;
				overflow-y: auto;
				width: 100%;
				padding: 0.7rem 0;
			}


			& div.row {
				display: flex;
				&.hl {
					color: #9b9b9b;
					font-weight: bold;
				}
				& div.item {
					text-align: right;
					padding: 0 .2rem; 
					&:nth-child(1){
						min-width: 1rem;
						& svg {
							fill: #758a75;
							&:hover {
								fill: #16c429;
							}
						}
					}
					&:nth-child(2){
						min-width: 3rem;
						color: gray;
					}

					&:nth-child(3){
						min-width: 11rem;
						font-weight: bold;
					}

					&:nth-child(4){
						min-width: 11rem;
						font-weight: bold;
					}
					
					&:nth-child(5){
						min-width: 11rem;
						color: gray;
					}
				}
			}		
		}
	}



	.msgValidate {
		padding: .4rem;
		border: 1px solid red;
		border-left: 8px solid red;
		margin-bottom: 1rem;
		text-align: left;
	}

#accEdit #userGrp  {

background-color: white;
border: 1px solid silver;
border-radius: 4px;
width: 100%;

}

div.accountsCont div.btns {
	display: flex;
	margin: 0 0 0.7rem 0;

}

div.accountsCont div.colBtn {
	border: 1px solid silver;
	border-radius: 4px;
	padding: 0.1rem 0.5rem;
	margin: 0.3rem;
	display:flex;
	align-items: center;
	cursor: pointer;
	width: 9rem;



	& > * {
		cursor: pointer;
	}

	& > svg {
		margin-right: 0.3rem;
	}


	&:hover {
		background-color: var(--c11);
		border: 1px solid white;
		color: white;

		& svg {
			filter: grayscale(1) contrast(0);

		}

	}

}

</style>
