<template>
	<div class="loginCont">
	Test <br>
	{{ ugDisplay }}
	</div>
</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 

	export default {
		name: 'Test',
		components: {
			// dialogComp,
		},
		beforeMount(){

		},
		mounted(){


			// let x = tools.isAdminProdOrPartner();
			// console.log(x);

			// console.log(tools.isInUserGroup('admin'));

			this.ugDisplay = tools.showUserGroup();
			console.log(tools.doesUserGroupMatchTo(['p-.+', 'prod.*', 'admin']));

		}, // end mounted 
		data: 
		function(){
			return {
				ugDisplay : null,

			}
		}, // end data
		  props: {


  	},
  	methods: {
  	

		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

	

</style>
