<template>
	<div class="modal">



		<div class="modalCont">


			<div class="modalHead">
				<div v-if="dialogData.title">
					{{ dialogData.title }}
				</div>
			</div>

			<div class="modalMain">
<!-- 				<div v-if="dialogData.text" v-html="dialogData.text"></div> -->
	<!-- {{ dialogData.request }} -->

	<div v-if="dialogData.list1 && dialogData.list1.length > 0">
		<div v-for="v in dialogData.list1">
			{{ v }}
		</div>
	</div>


			</div>

			<div class="modalFooter">
				<div  v-if="dialogData.btn1"><button :value="dialogData.btn1" @click="closeModal(0,$event)" class="btn btn-outline-secondary">{{ dialogData.btn1 }}</button></div>
				<div  v-if="dialogData.btn2"><button :value="dialogData.btn2" @click="closeModal(1,$event)" class="btn btn-outline-secondary">{{ dialogData.btn2 }}</button></div>
			</div>
		</div>
	</div>
</template>

<script>


	export default {
		name: 'Dialog',
		components: {

		},
		props:[
		'par'

		],
		mounted(){


		}, // end mounted 
		data: 
		function(){
			return {
				dialogData : this.par,

			}
		}, // end data
		methods: {
			closeModal(p,e){

				e.preventDefault();

				if (p == 1) {
					this.dialogData.response = 1;

				} else {

					this.dialogData.response = 0;
				}

				this.dialogData.callback();
				this.dialogData.vis = false;


				// this.dialogData.response = p;
				// 



			},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			min-height: 1rem;
			width: 48rem;
			text-align: center;


		}

		& .modalHead {
			text-align: center;
			background-color: var(--c11);
			color: white;
			font-weight: bold;
			padding: 0.3rem 1rem;
			margin-bottom: 0.7rem;

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}


</style>
