<template>

	<div class="modal" ref="selectDialogDialog">
		<div @keyup.esc = "closeModal()" class="modalCont">

			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>


				<div class="header">
					{{ par.request[0] }} wählen 
				</div>

				<input @keyup="refreshFilter()" ref="searchbox" type="text" placeholder="Filter">

				<div v-if="indexFilter" @keyup.ctrl.enter="takeSelection()" class="index" tabindex="-1">
					<div v-for="(v, k) in indexFilter" @click="selectItem(k)" @keydown.enter="selectItem(k)" :data-id="v.id" v-bind:class="[v.sel ? 'sel' : '']" class="listItem" tabindex="0">
						{{ v.val }}
					</div>
				</div>

				<div class="footer">
					<div @click="closeModal()" class="btn" title="esc">Abbrechen</div> 
					<div v-if="par.multiIstance" @click="takeSelection()" class="btn" title="ctrl enter">OK</div> 
				</div>

		</div>
	</div>

</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'SelectDialog',
		mounted(){

			// this.getTabIndex();

			this.getIndex();


			let self = this;
			setTimeout(function () {
				self.$refs.searchbox.focus();
			}, 140);

		},
		data: function(){
			return {
				index: null,
				indexFilter: null,
				dialogData : this.par,
				listItems: null,
				listItemSel: null,

			}
		},
		props:[
		'par'

		],
		methods:{
			getTabIndex(){


				// const root = document.getElementsByClassName('modalCont')[0];


				this.listItems = document.getElementsByClassName('listItem');





			},
			takeSelection(){


				let ids = [];
				let text = '';
				let o = [];

				for (const i  of this.indexFilter ){

					if(i.sel){

						o.push(i);
						ids.push(i.id);
						text +=  i.val + ', ';
					}
				}

				text =	text.substring(0, text.length - 2);

				this.dialogData.response = [ids, text, o];
				this.dialogData.callback();

			},

		selectItem(k){

			if (this.dialogData.multiIstance === false) {
				
				for (const i of this.indexFilter){
					i.sel = false;
				}
			}

			if (this.indexFilter[k].sel === true) {

				this.indexFilter[k].sel = false;
			} else {
				this.indexFilter[k].sel = true;
			}


			if(this.par.multiIstance === false){

				const self = this;
				setTimeout(function(){
					self.takeSelection();
				},100);

			} 

		},
			closeModal(){

				this.dialogData.vis = false;
				this.$parent.goToRemFocus();
			},
			refreshFilter(){

				const sTag = this.$refs.searchbox.value;
				let timeout = null;


				clearTimeout(timeout);

				let self = this;
				timeout = setTimeout(function () {

					self.indexFilter = [];
					for (const item  of self.index ){

						var re = new RegExp(sTag, 'ig');
						if (item.val.match( re ) ) {

							self.indexFilter.push(item);
						}
					}

				}, 400);
			},

			getIndex(){

				let self = this;
				const url = this.$parent.$parent.$parent.serverRoot + this.par.request[1];
				let ap = {sid: tools.getSidFromCookie()};


				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {
						request: this.par.request
					},

				})
				.then(function (resp) {

					self.indexFilter = self.index = resp.data.data[1];

					let selIds;


					let t = '';
					if (self.par.request[2]) {
						
					t = self.par.request[2].toString();
					} else {
						return t;
					}

					if (t.includes(',')) {

						let r = t.split(',');

						selIds = r;

					} else {

						let r = parseInt(t);

						if (Number.isNaN(r)) {
							selIds = [];
						} else {
							selIds =  [r.toString()];
						}
					}

					for (const item  of self.indexFilter  ){


						for (const i of selIds){

							if(item.id == i){
								item.sel = true;
							}
						}

					}

				});

			}, 

		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				
			}

			& .footer {
				display: flex;
				justify-content: flex-end;
				
				& div.btn {
					padding: 0.1rem 0 0;
					min-width: 8.1rem;
					margin: 0 0.1rem;

					&:hover {
						background-color: var(--c11);
						color: #fff;
						border: 1px solid var(--c11);
					}	
				}
			}

		}
	}

	div.index {
		text-align: left;
		height: 35rem;
		overflow: auto;
		margin: 1rem 0rem;
		font-size: .8rem;

		& > div {
			margin: 2px 0;
			padding: .1rem 0.5rem;

			&:hover {
				background-color: #FDEECC;
				border: none;
				
			}
		}

		& .sel {
			background-color: #ffff0036;
			
		}

	}

	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

</style>
