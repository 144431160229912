<template>
	<div class="loginCont">

		<div v-if="loginVis">
			<div v-if="false">
				<div class="">
					<div class="" style="margin: 0 auto">
						<button class="" @click="doLogout">logout</button>
					</div>
				</div>
			</div>

			<div >
				<div @keyup.enter="doLogin" class="">
					<div class="" style="margin: 0 auto">
						<div ref="msgValidate"></div>

						<div class="inputCont">
							<input class="" type="text" placeholder="username" v-model="user">
							<input class="" type="password" placeholder="password" v-model="pw" @keydown.enter="doLogin">
							<div class="btn btn1 " @click="doLogin">login</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 

	export default {
		name: 'Login',
		components: {
			// dialogComp,
		},
		beforeMount(){
			this.tryLogIn();
		},
		mounted(){


			// this.$parent.$parent.checkSid().then(function(data){

			// 	this.$parent.$parent.setSid(data.sid);

			// });


		}, // end mounted 
		data: 
		function(){
			return {
			// user: 'metnitzer',
			// pw: 'jjjjjjjj',			
				user: '',
			pw: '',
			loginVis: false,
			}
		}, // end data
		  props: {
		  	serverRoot: String,

  	},
  	methods: {
  		isInUserGroup(){
  			// return tools.isInUserGroup([1,2]);
  		},
  		tryLogIn(){

  			let goodQuery = '/login/biene-' + new Date().getDate();




  			if (this.$route.path === goodQuery){
  				this.loginVis = true;

  			} else {
  				this.loginVis = false;
  				this.$router.replace('/');
		}

	},
				doLogin(){


			// doValidate
			let valArr = [
			['Username', this.user,'notNull'], 
			['Passwort', this.pw,'notNull'],
			];

			let valMsg = tools.doValidate(valArr);

			this.$refs.msgValidate.innerHTML = '';
			this.$refs.msgValidate.classList.remove("msgValidate");
			if(valMsg)
			{
				this.$refs.msgValidate.innerHTML = valMsg;
				this.$refs.msgValidate.classList.add("msgValidate");
				return false;
			}

			let url = this.serverRoot + '/api/login.php';
			let self = this;

			// let ap = JSON.stringify({sid: tools.getSidFromCookie(), user: this.user, pw: this.pw});
			let ap = {sid: tools.getSidFromCookie(), user: this.user, pw: this.pw};


			axios.post(url, {
				crossDomain: true,
				headers: {
				},
				auth: ap,
				data: {

				},
			})
			.then(function (resp) {


				
				// console.log("LOGIN data ready");
				if (resp.data.messages) {
					alert(resp.data.messages);
				} else {
					
				// toDo
				self.$parent.$parent.setSid(resp.data.sid);

				setTimeout(function(){
					// self.$router.push({ path: '/firma' });
					window.location.href = '/firma';
				},1000);
						
				


				}
			})
			.catch(function (error) {
				console.log(error);
			});
		} // end doLogin


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

	.loginCont {
		max-width: 40rem;
		margin: 3rem auto;

padding: 1rem;
border: 1px solid silver;
border-radius: 6px;
			
		
	}

	.loginCont .inputCont {
		justify-content: center;
		flex-wrap: wrap;
		& > input, & > div {

			flex: 0 0 86%;
			margin: 0.2rem;
			
		}

		.btn {
			max-width: 24rem;
			cursor: pointer;

			background-color: orange;
			color: white;
			margin-top: 1.2rem;
			&:hover {

			background-color: #e89600;
			}
		}

	}

	.msgValidate {
		padding: .4rem;
		border: 1px solid red;
		border-left: 8px solid red;
		margin-bottom: 1rem;
		text-align: left;
	}

</style>
