<template>

	<div class="modal" ref="auftrSprecherDialog"  id="auftrSprecherDialog">
		<div class="modalCont">

			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>


				<div class="header">
					Sprecher wählen
				</div>

				<input @keyup="refreshDataFilter()" tabindex="0" ref="sbData" type="text" placeholder="Sprecher Filter">

				<div class="indexMus"  tabindex="-1" @click="selectItem">
					<div v-for="v in dataFilter" :data-id="v.display"  tabindex="0">
						{{ v.name }}
					</div>
				</div>


		</div>
	</div>

</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'AuftrSprecherDialog',
		mounted(){

			this.getIndex();

			let self = this;
			setTimeout(function () {
				// self.$refs.sbData.focus();
			}, 140);

		},
		data: function(){
			return {
				dialogData : this.par,
				data: null,
				dataFilter: null,

			}
		},
		props:[
		'par'

		],
		methods:{
			selectItem(e){

				this.par.response = [e.target.getAttribute('data-id'), e.target.innerText];
				this.dialogData.callback();
			},
		
			closeModal(){

				this.dialogData.vis = false;
				this.$parent.goToRemFocus();

			},


			getIndex(){


				let self = this;
				const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/getSprecherIndex.php';
				let ap = {sid: tools.getSidFromCookie()};

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {},

				})
				.then(function (resp) {


					self.$parent.$parent.$parent.setSid(resp.data.sid);

					self.data = resp.data.data[1][0];
					self.dataFilter = resp.data.data[1][0];

				});

			}, 
			refreshDataFilter(){

				const sTag = this.$refs.sbData.value;
				let timeout = null;

				clearTimeout(timeout);

				let self = this;
				timeout = setTimeout(function () {

					self.dataFilter = [];
					for (const item  of self.data ){

						var re = new RegExp(sTag, 'ig');
						if (item.name.match( re ) ) {

							self.dataFilter.push(item);
						}
					}

				}, 400);
  },

		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				

			}

		}
	}

	div.indexMus {
		text-align: left;
		height: 35rem;
		overflow: auto;
		margin: 1rem 0rem;


		& > div {
			margin: 2px 0;
			padding: .1rem 0.5rem;

			&:hover, &:focus {
				background-color: #FDEECC;
				border: none;
				
			}
		}

	}

	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

</style>
