<template>

	<div class="modal" ref="firmaNeu">
		<div class="modalCont">

			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>


			<div class="header">
				Neue Firma anlegen
			</div>

			<div ref="msgValidate" ></div>


			<div id="scrollCont" class="firmaNeuCont">
				<div class="row">

					<div  class="col c4-8">
						<span><span>Firma</span></span>
						<input @click="resetInput()" ref="firmaInput" @keyup.enter="checkNewFirma()" tabindex="0" v-model="firma" class="textLeft " type="text" name="firma">
					</div>

					<div v-if="userGroup === 'admin'"  class="col c4-8">
						<span><span>User Group</span></span><br>
						<select id="userGrp" v-model="selUserGroup" readonly>
							<option v-for="g in user_groups" :value="g.id">{{ g.id }}</option>
						</select>
					</div>



					<div style="display: none" class="col c4-8">
						<span>Anschrift 1 (Strasse)</span>
						<input tabindex="0" v-model="anschrift1" name="anschrift1" class="textLeft " type="text">
					</div>
				</div> <!-- end of row -->



			</div> <!-- end of container -->

				<div class="row">

					<div class="nfCheck" ref="nfCheck">
						<div class="headl">Folgende Firmen ähneln der Eingabe.
							<div><span @click="firmaAnlegen()" class="btnTa btn btnOk">trotzdem anlegen</span>&nbsp;<span>oder aus Liste wählen.</span></div>
						</div>

						<div class="cont">
							<div @click="firmaOeffnen(v.id)" v-for="(v,i) in antwort">
								{{ v.firma }}
							</div>
						</div>


					</div>

			</div> <!-- end of container -->

				<div class="row">
					<div @click="checkNewFirma()" class="btn btnOk">Firma anlegen</div>
					<div @click="closeModal()" class="btn btnCancel">Abbrechen</div>
			</div> <!-- end of container -->





		</div>
	</div>

	</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'FirmaNeu',
		mounted(){


			let self = this;
			setTimeout(function () {
				self.$refs.firmaInput.focus();
			}, 140);


			this.getUserGroups();
		},
		data: function(){
			return {
				dialogData : this.par,
				userGroup: this.par.request[0],
				selUserGroup: this.par.request[0],
				firma: '',
				anschrift1: '',
				antwort: [],
				user_groups: null,

			}
		},
		props:[
		'par'

		],
		methods:{

			getUserGroups(){



				let self = this;

				const url = this.$parent.$parent.$parent.serverRoot + '/api/getUserGroups.php';
				let ap = {sid: tools.getSidFromCookie()};

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {},

				})
				.then(function (resp) {

					// todo
					// self.$parent.$parent.$parent.setSid(resp.data.sid);


					let op = [];
					for (const i of resp.data.data[1]) { 

						if (i.id === 'admin' || i.id === 'prod') {
							continue;
						}

					op.push(i);
					} 

					self.user_groups = op;
					if (Object.keys(self.user_groups).length > 0) {
						self.selUserGroup = self.user_groups[Object.keys(self.user_groups)[0]].id;
					}

					
				});


			},

			resetInput(){
				this.firma = '';
				this.antwort = [];
				this.$refs.nfCheck.style.display = 'none';
			},
			firmaAnlegen(){

				var check = confirm('Wollen Sie die neue Firma "' + this.firma + '" anlegen?'); 
				if (check == false) {
					return false;
				}


				let self = this;

				const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/saveNewFirma.php';
				let ap = {sid: tools.getSidFromCookie()};



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {firma: this.firma, owner: this.selUserGroup},

				})
				.then(function (resp) {

					// todo
					self.$parent.$parent.$parent.setSid(resp.data.sid);

					let res = resp.data.data;

					self.dialogData.response = res;
					self.dialogData.callback();
					
				});


			},
			firmaOeffnen(id){

				this.dialogData.response = [true, id];
				this.dialogData.callback();

			},
			checkNewFirma(){

			// doValidate
			let valArr = [
			['Firma', this.firma,'notNull'], 
			// ['Anschrift 1', this.anschrift1,'notNull'],
			];

			let valMsg = tools.doValidate(valArr);

			this.$refs.msgValidate.innerHTML = '';
			this.$refs.msgValidate.classList.remove("msgValidate");
			if(valMsg)
			{
				this.$refs.msgValidate.innerHTML = valMsg;
				this.$refs.msgValidate.classList.add("msgValidate");
				return false;
			}

			let self = this;

			const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/checkNewFirma.php';
			let ap = {sid: tools.getSidFromCookie()};

			axios.post(url, {
				crossDomain: true,
				headers: {
				},
				auth: ap,
				data: {firma: this.firma},

			})
			.then(function (resp) {

					// todo
					self.$parent.$parent.$parent.setSid(resp.data.sid);

					let antwort = resp.data.data[0];

					if (antwort.length === 0) {
						confirm("save neue Firma");
						self.firmaAnlegen();
					} else {

						self.antwort = antwort;
						self.$refs.nfCheck.style.display = 'block';


					}



				});



		},
		closeModal(){

				this.dialogData.vis = false;


			},


		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				

			}

		}
	}


	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

		.firmaNeuCont {
			font-size: .9rem;
		}

		.btn {
			border: 1px solid silver;
			border-radius: 4px;
			display: inline-block;
			padding: 0.1rem 0.6rem;
			vertical-align: middle;
			min-width: 9rem;
			text-align: center;
		 
			&.btnOk {
				&:hover {
					color: white;
					background-color: #5db05d;
					border: 1px solid transparent;
				}
			}
			&.btnCancel {
				&:hover {
					color: white;
					background-color: #b77373;
					border: 1px solid transparent;
				}
			}
		}


	textarea {
		 resize:vertical;
	}


	.c8-8 {
		width: 911px;
	}

	.c7-8 {
		width: 795px;
	}

	.c6-8 {
		width: 679px;
	}

	.c5-8 {
		width: 563px;
	}

	.c4-8 {
		width: 447px;
	}

	.c3-8 {
		width: 332px;
	}

	.c2-8 {
		width: 216px;
	}

	.c1-8 {
		width: 100px;
	}


	.colBlank {
	}

	.row  {
		display: flex;
		text-align: left;
		justify-content: flex-start;

		& > div {
			margin: .5rem;
		}

	}


	.firmaNeuCont .row div.url span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

	.firmaNeuCont .row div.email span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

		.firmaNeuCont .row div.icLupe span  {
		position: relative;
		cursor: pointer;

		& span:nth-child(2) {
			position: absolute;
			bottom: -2px;
			left: 5rem;
		}
	}

	.firmaNeuCont .row div.cbCont  {
		display: flex;
		flex-direction: row;
		align-items: center;

		& span:nth-child(2) {
			font-size: .7rem;
		}
		
	}

	.firmaNeuCont .row div.rabattCont  {
		display: flex;
		flex-direction: row;

		& > div span {
			font-size: .7rem;
		}
		// flex-direction: row;
		// align-items: center;

		& div:nth-child(1) input {
			width: 3rem;
		}
		& div:nth-child(2) input {
			width: 6rem;
		}
		
	}

	.firmaNeuCont .row div.kundeVonCont {
		& input {
			width: 93%
		}
		
	}

	.msgValidate {
		padding: .4rem;
		border: 1px solid red;
		border-left: 8px solid red;
		margin-bottom: 1rem;
		text-align: left;
	}

	.nfCheck {
		background-color: #F2F2F2;
		width: 100%;
		min-height: 7rem;
		padding: 1rem;
		border-radius: 6px;
		display: none;


	}

	.nfCheck {

		& > .headl {


			

			&  .btnTa {
				display: inline-block;
				margin: 1rem 0;
				background-color: #f2f2c2;
				&:hover {
									color: white;
					background-color: #5db05d;
					border: 1px solid transparent;

				}

			}
		}


		& > .cont {
			padding: .2rem;
			border: 1px solid silver;
			border-radius: 4px;
			background-color: white;
			min-height: 7rem;
			max-height: 23rem;
			overflow-x: auto;

			& > div {
				cursor: pointer;
				padding: 0.4rem;
				&:hover {

				background-color: #f2f2c2;

				}
			}
		}
	}

#userGrp {
border: 1px solid silver;
background: white;
width: 100%;
}
</style>
