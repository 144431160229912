<template>

	<div id="accEdit" class="modal" ref="accountEdit">
		<div class="modalCont">


			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>



			<div class="header">
				User Edit
			</div>

			<div ref="msgValidate" ></div>


			<div id="scrollCont" class="accountEditCont">
				
				<div class="row">

					<div  class="col c8-8">
						<span><span>userName</span></span>
						<input  ref="accountEditInput"  tabindex="0" v-model="u.user" class="textLeft " type="text" name="firma">
					</div>

					<div  class="col c8-8">
						<span><span>Passwort</span></span>
						<input  ref="accountEditInput"  tabindex="0" v-model="u.password" class="textLeft " type="text" name="firma">
					</div>

				</div> <!-- end of row -->
				
				<div class="row">

					<div  class="col c8-8">
						<span><span>User Group</span></span><br>
						<select id="userGrp" v-model="u.group_id">
							<option v-for="g in user_groups" :value="g.id">{{ g.id }}</option>
						</select>
					</div>

					<div  class="col c8-8">
						<span><span>Group Comments</span></span>
						<input  ref="accountEditInput"  tabindex="0" v-model="u.groups_comments" class="textLeft " type="text" name="firma">
					</div>

				</div> <!-- end of row -->			



			</div> <!-- end of container -->


				<div class="row">
					<div @click="tryToSave()" class="btn btnOk">OK</div>
					<div @click="closeModal()" class="btn btnCancel">Abbrechen</div>
					<div @click="cudUserAccount('d')" class="colBtn">
						<svg width="16" height="16"><use xlink:href="@/assets/img/icons.svg#btnRemove"/></svg>
						<span>User löschen</span>
					</div>
			</div> <!-- end of container -->





		</div>
	</div>

	</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'accountEdit',
		mounted(){


			this.u.password = null;
			this.getUserGroups();

		},
		data: function(){
			return {
				dialogData : this.par,
				u: this.par.request[0],
				user_groups: null,

			}
		},
		props:[
		'par'

		],
		methods:{
			resetInput(){

			},
			tryToSave(){
				let o = this.dialogData.request[0];
				let modus = this.dialogData.request[1];

				if (modus === 'new') {
					this.insertAccound();
				} else if (modus === 'edit'){
					this.updateAccound();
				}

			},

			updateAccound(){

				// doValidate
				let valArr = [
					['userName', this.u.user,'notNull'], 
					['group_id', this.u.group_id,'notNull'], 
					];

				let valMsg = tools.doValidate(valArr);

				this.$refs.msgValidate.innerHTML = '';
				this.$refs.msgValidate.classList.remove("msgValidate");
				if(valMsg)
				{
					this.$refs.msgValidate.innerHTML = valMsg;
					this.$refs.msgValidate.classList.add("msgValidate");
					return false;
				}

				this.cudUserAccount('u');

			},
			insertAccound(){
				
				// doValidate
				let valArr = [
					['userName', this.u.user,'notNull'], 
					['Passwort', this.u.password,'notNull'], 
					['group_id', this.u.group_id,'notNull'], 
					];

				let valMsg = tools.doValidate(valArr);

				this.$refs.msgValidate.innerHTML = '';
				this.$refs.msgValidate.classList.remove("msgValidate");
				if(valMsg)
				{
					this.$refs.msgValidate.innerHTML = valMsg;
					this.$refs.msgValidate.classList.add("msgValidate");
					return false;
				}

				this.cudUserAccount('i');
			},

			cudUserAccount(modus){
				// modus i|u|d

				let self = this;

				const url = this.$parent.$parent.$parent.serverRoot + '/api/cudUserAccount.php';
				let ap = {sid: tools.getSidFromCookie()};

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {modus: modus, user: this.u},

				})
				.then(function (resp) {

					// todo
					// self.$parent.$parent.$parent.setSid(resp.data.sid);

					let res = resp.data.data;

					self.dialogData.response = res;
					self.dialogData.callback();

					
				});


			},
			getUserGroups(){



				let self = this;

				const url = this.$parent.$parent.$parent.serverRoot + '/api/getUserGroups.php';
				let ap = {sid: tools.getSidFromCookie()};

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					auth: ap,
					data: {},

				})
				.then(function (resp) {

					// todo
					// self.$parent.$parent.$parent.setSid(resp.data.sid);

					let res = resp.data.data;

					self.user_groups = resp.data.data[1];

					
				});


			},

	closeModal(){

				this.dialogData.vis = false;


			},


		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				

			}

		}
	}


	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

		.accountEditCont {
			font-size: .9rem;
		}

		.btn {
			border: 1px solid silver;
			border-radius: 4px;
			display: inline-block;
			padding: 0.1rem 0.6rem;
			vertical-align: middle;
			min-width: 9rem;
			text-align: center;
		 
			&.btnOk {
				&:hover {
					color: white;
					background-color: #5db05d;
					border: 1px solid transparent;
				}
			}
			&.btnCancel {
				&:hover {
					color: white;
					background-color: #b77373;
					border: 1px solid transparent;
				}
			}
		}


	textarea {
		 resize:vertical;
	}


	.c8-8 {
		width: 911px;
	}

	.c7-8 {
		width: 795px;
	}

	.c6-8 {
		width: 679px;
	}

	.c5-8 {
		width: 563px;
	}

	.c4-8 {
		width: 447px;
	}

	.c3-8 {
		width: 332px;
	}

	.c2-8 {
		width: 216px;
	}

	.c1-8 {
		width: 100px;
	}


	.colBlank {
	}

	.row  {
		display: flex;
		text-align: left;
		justify-content: flex-start;

		& > div {
			margin: .5rem;
		}

	}


	.accountEditCont .row div.url span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

	.msgValidate {
		padding: .4rem;
		border: 1px solid red;
		border-left: 8px solid red;
		margin-bottom: 1rem;
		text-align: left;
	}


#userGrp {
border: 1px solid silver;
background: white;
width: 100%;
}

 div.colBtn {
	border: 1px solid silver;
	border-radius: 4px;
	padding: 0.1rem 0.5rem;
	display:flex;
	cursor: pointer;
	width: 9rem;



	& > * {
		cursor: pointer;
	}

	& > svg {
		margin-right: 0.3rem;
	}


	&:hover {
		background-color: var(--c11);
		border: 1px solid white;
		color: white;

		& svg {
			filter: grayscale(1) contrast(0);

		}

	}

}

</style>
