<template>

	<div class="modal" ref="auftragNeu">
		<div class="modalCont">

			<div @click="closeModal()" class="btnClose">
				<svg width="16" height="16" ><use xlink:href="@/assets/img/icons.svg#btnClose"/></svg>
			</div>


			<div class="header">
				Neuen Auftrag anlegen
			</div>

			<div ref="msgValidate" ></div>


			<div id="scrollCont" class="auftragNeuCont">
				<div class="row">

					<div  class="col c4-8">
						<span><span>Auftragsnummer</span></span>
						<input  ref="auftragInput" tabindex="0" v-model="atnummer" class="textLeft " type="text" name="atnummer">
					</div>

				</div> <!-- end of row -->



			</div> <!-- end of container -->


				<div class="row">
					<div @click="auftragAnlegen()" class="btn btnOk">Auftrag anlegen</div>
					<div @click="closeModal()" class="btn btnCancel">Abbrechen</div>
			</div> <!-- end of container -->


		</div>
	</div>

	</template>

<script>
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	export default {
		name: 'AuftragNeu',
		mounted(){


			let self = this;
			setTimeout(function () {
				self.$refs.auftragInput.focus();
			}, 140);



		},
		data: function(){
			return {
				dialogData : this.par,
				atnummer: '',

			}
		},
		props:[
		'par'

		],
		methods:{
			resetInput(){
				// this.firma = '';
				this.antwort = [];
				this.$refs.nfCheck.style.display = 'none';
			},
			auftragAnlegen(){

			// doValidate
			let valArr = [
			['Auftragsnummer', this.atnummer,'notNull'], 
			];

			let valMsg = tools.doValidate(valArr);

			this.$refs.msgValidate.innerHTML = '';
			this.$refs.msgValidate.classList.remove("msgValidate");
			if(valMsg)
			{
				this.$refs.msgValidate.innerHTML = valMsg;
				this.$refs.msgValidate.classList.add("msgValidate");
				return false;
			}


			const fId = this.dialogData.request[0].id;
			let self = this;

			const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/saveNewAuftrag.php';
			let ap = {sid: tools.getSidFromCookie()};

			axios.post(url, {
				crossDomain: true,
				headers: {
				},
				auth: ap,
				data: {
					atnummer: this.atnummer,
					fId:fId,
				}
			})
			.then(function (resp) {

					// todo
					self.$parent.$parent.$parent.setSid(resp.data.sid);




					self.dialogData.response = ['ok'];
					self.dialogData.callback();
					
				});


			},
			// kontaktKopieren(id){

			// 	let self = this;

			// 	const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/copyNewKontakt.php';
			// 	let ap = {sid: tools.getSidFromCookie()};

			// 	axios.post(url, {
			// 		crossDomain: true,
			// 		headers: {
			// 		},
			// 		auth: ap,
			// 		data: {kontId: id, fId: this.dialogData.request[0].id},

			// 	})
			// 	.then(function (resp) {

			// 		// todo
			// 		self.$parent.$parent.$parent.setSid(resp.data.sid);

			// 		const ok = resp.data.data[0][0];
			// 		const id = resp.data.data[0][1];

			// 		self.dialogData.response = [ok, id];
			// 		self.dialogData.callback();



			// 	});



			// },
		// 	checkNewAuftrag(){

		// 	// doValidate
		// 	let valArr = [
		// 	['Vorname', this.vorname,'notNull'], 
		// 	['Nachname', this.nachname,'notNull'], 
		// 	];

		// 	let valMsg = tools.doValidate(valArr);

		// 	this.$refs.msgValidate.innerHTML = '';
		// 	this.$refs.msgValidate.classList.remove("msgValidate");
		// 	if(valMsg)
		// 	{
		// 		this.$refs.msgValidate.innerHTML = valMsg;
		// 		this.$refs.msgValidate.classList.add("msgValidate");
		// 		return false;
		// 	}

		// 	let self = this;

		// 	const url = this.$parent.$parent.$parent.serverRoot + '/api/firma/checkNewAuftrag.php';
		// 	let ap = {sid: tools.getSidFromCookie()};

		// 	axios.post(url, {
		// 		crossDomain: true,
		// 		headers: {
		// 		},
		// 		auth: ap,
		// 		data: {vn: this.vorname, nn: this.nachname},

		// 	})
		// 	.then(function (resp) {

		// 			// todo
		// 			self.$parent.$parent.$parent.setSid(resp.data.sid);

		// 			let antwort = resp.data.data[0];

		// 			if (antwort.length === 0) {
		// 				self.auftragAnlegen();
		// 			} else {

		// 				self.antwort = antwort;
		// 				self.$refs.nfCheck.style.display = 'block';


		// 			}



		// 		});



		// },
		closeModal(){

				this.dialogData.vis = false;


			},


		} //  methods end
	}


</script>

<style lang="scss" scoped>

	div .modal {

		background: rgba(0,0,0,0.2);

	}


	.modal {
		position: absolute;
		top: 0;
		left: 0rem;
		background-color: rgb(0,0,0, .5);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		& > .modalCont {

			position: relative;
			top: 2rem;
			background-color: white;
			padding: 2rem;
			border-radius: 8px;
			box-shadow: 2px 2px 2px #b0b0b0;
			width: 50rem;

			& .header {
				box-sizing: border-box;
				background-color: var(--c11);
				color: white;
				font-weight: bold;
				padding: .2rem 1rem;
				border-radius: 4px;
				margin-bottom: 1rem;
				width: 100%;				
			}

			& input {
				box-sizing: border-box;
				width: 100%;				

			}

		}
	}


	div.btnClose {
		position: absolute;
		right: 10px;
		top: 12px;
		cursor: pointer;
	}

		.kontaktNeuCont {
			font-size: .9rem;
		}

		.btn {
			border: 1px solid silver;
			border-radius: 4px;
			display: inline-block;
			padding: 0.1rem 0.6rem;
			vertical-align: middle;
			min-width: 9rem;
			text-align: center;
		 
			&.btnOk {
				&:hover {
					color: white;
					background-color: #5db05d;
					border: 1px solid transparent;
				}
			}
			&.btnCancel {
				&:hover {
					color: white;
					background-color: #b77373;
					border: 1px solid transparent;
				}
			}
		}


	textarea {
		 resize:vertical;
	}


	.c8-8 {
		width: 911px;
	}

	.c7-8 {
		width: 795px;
	}

	.c6-8 {
		width: 679px;
	}

	.c5-8 {
		width: 563px;
	}

	.c4-8 {
		width: 447px;
	}

	.c3-8 {
		width: 332px;
	}

	.c2-8 {
		width: 216px;
	}

	.c1-8 {
		width: 100px;
	}


	.colBlank {
	}

	.row  {
		display: flex;
		text-align: left;
		justify-content: flex-start;

		& > div {
			margin: .5rem;
		}

	}


	.kontaktNeuCont .row div.url span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

	.kontaktNeuCont .row div.email span  {
		position: relative;

		& span:nth-child(2) {
			position: absolute;
			bottom: 0;
			left: 3.2em;
		}
	}

		.kontaktNeuCont .row div.icLupe span  {
		position: relative;
		cursor: pointer;

		& span:nth-child(2) {
			position: absolute;
			bottom: -2px;
			left: 5rem;
		}
	}

	.kontaktNeuCont .row div.cbCont  {
		display: flex;
		flex-direction: row;
		align-items: center;

		& span:nth-child(2) {
			font-size: .7rem;
		}
		
	}

	.kontaktNeuCont .row div.rabattCont  {
		display: flex;
		flex-direction: row;

		& > div span {
			font-size: .7rem;
		}
		// flex-direction: row;
		// align-items: center;

		& div:nth-child(1) input {
			width: 3rem;
		}
		& div:nth-child(2) input {
			width: 6rem;
		}
		
	}

	.kontaktNeuCont .row div.kundeVonCont {
		& input {
			width: 93%
		}
		
	}

	.msgValidate {
		padding: .4rem;
		border: 1px solid red;
		border-left: 8px solid red;
		margin-bottom: 1rem;
		text-align: left;
	}

	.nfCheck {
		background-color: #F2F2F2;
		width: 100%;
		min-height: 7rem;
		padding: 1rem;
		border-radius: 6px;
		display: none;


	}

	.nfCheck {

		& > .headl {


			

			&  .btnTa {
				display: inline-block;
				margin: 1rem 0;
				background-color: #f2f2c2;
				&:hover {
									color: white;
					background-color: #5db05d;
					border: 1px solid transparent;

				}

			}
		}


		& > .cont {
			padding: .2rem;
			border: 1px solid silver;
			border-radius: 4px;
			background-color: white;
			min-height: 7rem;
			max-height: 23rem;
			overflow-x: auto;

			& > div {
				cursor: pointer;
				padding: 0.4rem;
				&:hover {

				background-color: #f2f2c2;

				}

				& > span{ display: inline-block }
				& > span:nth-child(1){  }
				& > span:nth-child(2){ min-width: 9rem; }
				& > span:nth-child(3){ 
					&::before {
						content: "Firma: ";
						color: gray;
					}
					font-size: .9rem; 
				}
			}
		}
	}

</style>
